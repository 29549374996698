import {
    Col,
    message,
    Row,
    Anchor,
    Form,
    Input,
    InputNumber,
    Upload,
    Select, Button, AutoComplete
} from "antd";
import React, {useEffect, useRef, useState} from "react";
import { getToken } from "../../helpers";
import {
    getAPIurl,
    getInitialValuesFormular, isTextFieldActive, LoadingScreen,
    locationSearch, MyInputNumber,
    optimizedHandleScroll, SaveIcon,
    updateDataContent, useModals
} from "../../helpers/formHelpers";
import { useParams } from "react-router-dom";

import TextArea from "antd/es/input/TextArea";
import { FileTextOutlined } from "@ant-design/icons";
import useScreenSize1 from "../../hooks/useScreenSize1";
import { useSwitch } from '../../pages/SwitchContext';
// import { HashLink as Link } from "react-router-hash-link";
import { RxCross2 } from "react-icons/rx";
import {RiDeleteBin5Fill} from "react-icons/ri";
const { Dragger } = Upload;
const { Link } = Anchor;
const { Option } = Select;
let dataContainer;
let dataContent;
let enumerationValuesContent;
let updateDataContentValue;
let updateDataContentContainer;
const putBody = {};


const AllgemeineAngaben = () => {
    const [vis, setVis] = useState(false);
    const { isDesktopView } = useScreenSize1();
    const { isSwitchOn, toggleSwitch } = useSwitch();
    // navigate = useNavigate();

    useEffect(() => {
        if (isDesktopView) {
            setVis(true);
        } else {
            setVis(false);
        }
    }, [isDesktopView]);
    const handleVisibilityChange = (newVisibility) => {
        setVis(newVisibility);
    };
    return (
        // <Row>
        //     <Col md={4} lg={4} sm={24} xs={24}>
        //         
        //     </Col>
        //     <Col md={20} lg={20} sm={24} xs={24} className="FormContainer">
        //         
        //     </Col>
        // </Row>
        <>
            <div className="w-full">
                <div
                    className={`${!isDesktopView ? "visible  cursor-pointer" : "hidden"
                        } text-white pl-[15px]`}
                    onClick={() => {
                        setVis(!vis);
                    }}
                >
                    <button className="menuBtn">Menu</button>
                </div>
                <div
                    className={`flex flex-row gap-[30px] relative  mr-[15px]  mb-[40px] ${!isDesktopView ? "ml-[15px]" : ""
                        }`}
                >
                    {vis ? (
                        <div className="w-[299px] absolute xl:relative z-10 h-full xl:h-auto  ">
                            <MenuContainer
                                vis={vis}
                                onVisibilityChange={handleVisibilityChange}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    <div className={`w-full ${isSwitchOn ? 'bg-black' : 'bg-white'} rounded-[30px]`}>
                        <FormContainer />
                    </div>
                </div>
            </div>
        </>
    );
};

const MenuContainer = ({ vis, onVisibilityChange }) => {
    window.addEventListener('scroll', optimizedHandleScroll);
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const [isInLower20, setIsInLower20] = useState(false);
    const handleClick = () => {
        const newVisibility = !vis;
        onVisibilityChange(newVisibility);
    };
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            // Check if the device height is less than 600px
            const isDeviceHeightBelow600 = window.innerHeight < 650;

            // Calculate the scroll position percentage
            const scrollPercentage = (scrollPosition / (documentHeight - windowHeight)) * 100;

            // Set the state based on the scroll position and device height
            setIsInLower20(isDeviceHeightBelow600 && scrollPercentage >= 80);
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`sidebar rounded-r-[30px] ${isSwitchOn ? 'bg-black' : 'bg-white'}`}>
            <div className={`ml-[197px]`}><RxCross2 onClick={handleClick} className={`xl:hidden ${isSwitchOn ? 'text-white' : 'text-blaxk'} text-[30px] mb-[20px] `} /></div>
            <Anchor affix={false} offsetTop={80} className={`your-component ${isInLower20 ? 'lower-20' : ''}`}>
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#beschaffenheit" title="Beschaffenheit" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#strasse-anschluesse" title="Straße & Anschlüsse" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#grenzverhaeltnisse-baugrund" title="Grenzverhältnisse & Baugrund" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#grundbuch" title="Grundbuch" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#planung" title="Planung" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#rechte-belastungen" title="Rechte & Belastungen" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#beurteilung-grundstueck" title="Beurteilung Grundstück" />

            </Anchor>
        </div>
    );
};


const FormContainer = () => {
    const [loading, setIsLoading] = useState(false);
    const [saveIcon, setSaveIcon] = useState(false);
    const [saveIcon1, setSaveIcon1] = useState(false);
    const [formFormular1] = Form.useForm();
    const inputRefs = useRef({});
    const [initialValues, setInitialValuesState] = useState([]);

    const handleRef = (name, element) => {
        if (element) {
            inputRefs.current[name] = element;
        }
    };
    const { auftragID, gutachtenID, tab } = useParams(); // Get the activeKey from the URL
    const [selectedOption, setSelectedOption] = useState(null);
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const { modals, handleOpenTextbausteine, handleCloseTextbausteine, renderModals, selectedTextBausteine } = useModals(); //Drawer for Textbausteine

    //console.log(gutachtenID);
    const handleSelectChange = (value) => {
        setSelectedOption(value);
    };

    const fetchAllgemeineAngaben = async () => {
        setIsLoading(true);

        try {
            const response = await fetch(`${getAPIurl()}/helper/acl/gutachten/${gutachtenID}?populate=deep`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            //console.log(`${API}/vollgutachtens/${gutachtenID}?populate=deep`);
            dataContainer = await response.json();
            dataContent = dataContainer;
            setInitialValuesState(getInitialValuesFormular(dataContent));

            const enumerationValues = await fetch(`${getAPIurl()}/gutachten/enumvalues`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            enumerationValuesContent = await enumerationValues.json();
        } catch (error) {
            console.log(error);
            message.error("Error while fetching profiles!" + error);
        } finally {
            //When everything is loaded jump to anchor (if selected)
            let currentUrl = window.location.href.split("#");
            if(currentUrl[1]){ // Anchor(=Hashtag) is in URL
                document.querySelector('a[href="#'+currentUrl[1]+'"]').click();
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAllgemeineAngaben().then();
        const interval = setInterval(() => {
            if(!isTextFieldActive()) { //Not if currently in a textfield
                fetchAllgemeineAngaben().then();
            }
        }, 30000); // Every 30 Seconds (in case multiple persons are working OR multiple tabs)
        return () => clearInterval(interval);

    }, []);
    const triggerFocus = (name) => {
        if (inputRefs.current[name]) {
            inputRefs.current[name].focus();
        }
    };
    useEffect(() => {
        if (selectedTextBausteine) {
            formFormular1.setFieldsValue({ [modals[modals.length - 1].field]: selectedTextBausteine });
            triggerFocus(modals[modals.length - 1].field);
        }
    }, [selectedTextBausteine]); // Specify the dependencies to watch for changes

    const updateGutachten = (putBody) => {
        setSaveIcon(true);
        setSaveIcon1(true)
        fetch(`${getAPIurl()}/helper/acl/gutachten/${gutachtenID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(putBody.data),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to update data.');
                }
                //console.log('putBody:', putBody);
                return response.json();
            })
            .then((responseData) => {
                // Handle the response data after the update
                //console.log('Updated data:', responseData);
                // Setze das Speicher-Icon nach X Millisekunden zurück
                setTimeout(() => { setSaveIcon(false); }, 300);
                setTimeout(() => {
                    setSaveIcon1(false);
                }, 3000);
            })
            .catch((error) => {
                console.log('putBody:', putBody);
                console.error('Error:', error);
            });
    }
    useEffect(() => formFormular1.resetFields(), [initialValues]);

    return (
        <div className={`${isSwitchOn ? 'bg-black' : 'bg-white'} sm:p-[20px] p-[10px] rounded-[30px]`}>
            {loading && <LoadingScreen />}
            {saveIcon1 && <SaveIcon saveIcon={saveIcon} />}
            {renderModals()}
            <h2 className={`${isSwitchOn ? 'text-white' : 'text-black'} font-bold text-16px  md:text-[28px] mb-[20px] generalText`}>Grundstück</h2>
            <div>
                {dataContent && initialValues && enumerationValuesContent && ( // Bedingung überprüft, ob dataContent bereits vorhanden ist
                    <Form
                        form={formFormular1}
                        onBlur={(e) => {
                            // Hier wird das onBlur-Ereignis für das gesamte Formular abgefangen
                            if (e.target.type != "button") { //Not at addCard or textbaustein
                                updateDataContentContainer = dataContent;
                                // enumarations
                                if (e.target.type == "search") {
                                    updateDataContentValue = selectedOption;
                                    setSelectedOption(null); //Reset (for the case the next select is choosen without value)
                                } else {
                                    updateDataContentValue = e.target.value;
                                }
                                const updateByIDArray = [];
                                if (updateByIDArray.some(value => e.target.id.includes(value))) {
                                    updateDataContent(e.target.id, updateDataContentValue, updateDataContentContainer, "updateByID");
                                } else {
                                    updateDataContent(e.target.id, updateDataContentValue, updateDataContentContainer, "updateByIndex");
                                }
                                putBody.data = dataContent;
                                updateGutachten(putBody);
                            }
                        }}
                        initialValues={initialValues}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                    // style={{ maxWidth: 800 }}
                    >
                        <div id="beschaffenheit" className="scrollable-section">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Beschaffenheit</h3>
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Straßenfront" name="Grundstueck__GS_Strassenfront">
                                    <Input className="inputsAll" />
                                </Form.Item>
                                <Form.Item label="Grundstückstiefe" name="Grundstueck__GS_Grundstueckstiefe">
                                    <Input className="inputsAll" />
                                </Form.Item>

                                <Form.Item label="Größe (in m²)" name="Grundstueck__GS_Groesse">
                                    <MyInputNumber className="inputsAll" />
                                </Form.Item>
                                <Form.Item label="Zuschnitt" name="Grundstueck__GS_ZuschnittV">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.sub_com_GS_Zuschnitt?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                        option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Zuschnitt"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                                <Form.Item label="Topographie" name="Grundstueck__GS_TopographieV">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent?.sub_com_GS_Topographie?.map(
                                            (option, index) => ({ value: option, key: index })
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Topographie"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>
                        <div id="strasse-anschluesse " className="border-t border-gray-300 pt-[20px] pb-[20px] scrollable-section">
                            <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Straße & Anschlüsse</h3>
                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Straßenart" name='Grundstueck__GS_Strassenart'>
                                    <TextArea autoSize={true} className="textInput" ref={(element) => handleRef('Grundstueck__GS_Strassenart', element)} />
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Grundstueck__GS_Strassenart', 'Straßenart', 'Straßenart')} style={{ float: 'right' }} ><FileTextOutlined /></Button>
                            </div>

                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Straßenausbau" name='Grundstueck__GS_Strassenbau'>
                                    <TextArea autoSize={true} className="textInput" ref={(element) => handleRef('Grundstueck__GS_Strassenbau', element)} />
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Grundstueck__GS_Strassenbau', 'Straßenausbau', 'Straßenausbau')} style={{ float: 'right' }} ><FileTextOutlined /></Button>
                            </div>

                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Anschlüsse" name='Grundstueck__GS_Anschluesse'>
                                    <TextArea autoSize={true} className="textInput" ref={(element) => handleRef('Grundstueck__GS_Anschluesse', element)} />
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Grundstueck__GS_Anschluesse', 'Anschlüsse', 'Anschlüsse')} style={{ float: 'right' }} ><FileTextOutlined /></Button>

                            </div>

                        </div>
                        <div id="grenzverhaeltnisse-baugrund" className="scrollable-section border-t border-gray-300 pt-[20px] pb-[20px]">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Grenzverhältnisse & Baugrund</h3>
                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Grenzverhältnisse" name='Grundstueck__GS_Grenzverhaeltnis'>
                                    <TextArea autoSize={true} className="textInput" ref={(element) => handleRef('Grundstueck__GS_Grenzverhaeltnis', element)} />
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Grundstueck__GS_Grenzverhaeltnis', 'Grenzverhältnisse', 'Grenzverhältnisse')} style={{ float: 'right' }} ><FileTextOutlined /></Button>
                            </div>


                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Baugrund & Grundwasser" name='Grundstueck__GS_Baugrund_Grundwasser'>
                                    <TextArea autoSize={true} className="textInput" ref={(element) => handleRef('Grundstueck__GS_Baugrund_Grundwasser', element)} />
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Grundstueck__GS_Baugrund_Grundwasser', 'Baugrund & Grundwasser', 'Baugrund/Grundwasser')} style={{ float: 'right' }} ><FileTextOutlined /></Button>

                            </div>


                        </div>
                        <div id="grundbuch" className="scrollable-section border-t border-gray-300 pt-[20px] pb-[20px]">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Grundbuch</h3>
                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Belastung Grundbuch" name='Grundstueck__RE_Belastung_Grundbuch'>
                                    <TextArea autoSize={true} className="textInput" ref={(element) => handleRef('Grundstueck__RE_Belastung_Grundbuch', element)} />
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Grundstueck__RE_Belastung_Grundbuch', 'Belastung Grundbuch', 'Belastung Grundbuch')} style={{ float: 'right' }} ><FileTextOutlined /></Button>

                            </div>


                        </div>
                        <div id="planung" className="scrollable-section border-t border-gray-300 pt-[20px] pb-[20px]">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Planung</h3>
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Flächennutzungsplan" name='Grundstueck__RE_FlaechennutzungsplanV'>
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.sub_com_RE_Flaechennutzungsplan?.map(
                                            (option, index) => ({ value: option, key: index })
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Flächennutzungsplan"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>

                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Bebauungsplan" name='Grundstueck__RE_Bebauungsplan'>
                                    <TextArea autoSize={true} className="textInput" ref={(element) => handleRef('Grundstueck__RE_Bebauungsplan', element)} />
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Grundstueck__RE_Bebauungsplan', 'Bebauungsplan', 'Bebauungsplan')} style={{ float: 'right' }} ><FileTextOutlined /></Button>

                            </div>


                        </div>
                        <div id="rechte-belastungen" className="scrollable-section border-t border-gray-300 pt-[20px] pb-[20px]">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Rechte & Belastungen</h3>
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Entwicklungszustand" name='Grundstueck__RE_EntwicklungszustandV'>
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.sub_com_RE_Entwicklungszustand?.map(
                                            (option, index) => ({ value: option, key: index })
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Entwicklungszustand"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                                <Form.Item label="Beitrags- & Abgabenzustand" name='Grundstueck__RE_Beitrags_AbgabenzustandV'>
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.sub_com_RE_Beitrags_Abgabenzustand?.map(
                                            (option, index) => ({ value: option, key: index })
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Beitrags- & Abgabenzustand"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                                <Form.Item label="Bodenordnungsverfahren" name='Grundstueck__RE_BodenordnungsverfahrenV'>
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.sub_com_RE_Bodenordnungsverfahren?.map(
                                            (option, index) => ({ value: option, key: index })
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Bodenordnungsverfahren"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                                <Form.Item label="Nicht eingetragene Rechte und Lasten" name='Grundstueck__RE_neing_RechteundLastenV'>
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.sub_com_RE_neing_RechteundLasten?.map(
                                            (option, index) => ({ value: option, key: index })
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Nicht eingetragene Rechte und Lasten"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                                <Form.Item label="Denkmalschutz" name='Grundstueck__RE_DenkmalschutzV'>
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.sub_com_RE_Denkmalschutz?.map(
                                            (option, index) => ({ value: option, key: index })
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Denkmalschutz"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                                <Form.Item label="Baulasten" name='Grundstueck__RE_BaulastenV'>
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.sub_com_RE_Baulasten?.map(
                                            (option, index) => ({ value: option, key: index })
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Baulasten"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                                <Form.Item label="Altlasten" name='Grundstueck__RE_AltlastenV'>
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.sub_com_RE_Altlasten?.map(
                                            (option, index) => ({ value: option, key: index })
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Altlasten"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>
                        </div>
                        <div id="beurteilung-grundstueck" className="scrollable-section border-t border-gray-300 pt-[20px] pb-[20px]">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Beurteilung Grundstück</h3>
                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Beurteilung Grundstück" name='Grundstueck__Beurteilung_Grundstueck'>
                                    <TextArea autoSize={true} className="textInput" ref={(element) => handleRef('Grundstueck__Beurteilung_Grundstueck', element)} />
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Grundstueck__Beurteilung_Grundstueck', 'Beurteilung Grundstück', 'Grundstück')} style={{ float: 'right' }} ><FileTextOutlined /></Button>
                            </div>

                        </div>

                    </Form>
                )}
            </div>
        </div>
    );
};

export default AllgemeineAngaben;
