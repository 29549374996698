import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    message,
    Form,
    Input,
    Select, notification
} from 'antd';
import { getToken } from "../helpers";
import {getAPIurl, getInitialValuesFormular, SaveIcon, updateDataContent} from "../helpers/formHelpers";
import { ArrowLeftOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { AutoComplete } from 'antd';
import '../Styles/Kontakte.css'
import { useSwitch } from '../pages/SwitchContext';
import { Link } from "react-router-dom";

const { Option } = Select;

let dataContainer;
let dataContent;
let enumerationValuesContent;
let updateDataContentValue;
const putBody = {};

const KontaktDetails = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true);
    const [saveIcon, setSaveIcon] = useState(false);
    const [saveIcon1, setSaveIcon1] = useState(false);
    const { kontaktID, auftragID } = useParams(); // Get the activeKey from the URL
    const [formFormular] = Form.useForm();
    const [selectedOption, setSelectedOption] = useState(null);
    const [highlightedFields, setHighlightedFields] = useState([]);
    const { isSwitchOn, toggleSwitch } = useSwitch();

    const handleSelectChange = (value) => {

        setSelectedOption(value);
    };

    const fetchKontakt = async () => {
        setIsLoading(true);
         dataContainer = null; //Reset if form was loaded with other data before
         dataContent = null;//Reset if form was loaded with other data before

        try {

            const response = await fetch(`${getAPIurl()}/helper/acl/kontakte/${kontaktID}?populate=deep`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            dataContent = await response.json();
            const enumerationValues = await fetch(`${getAPIurl()}/kontakte/enumvalues`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            enumerationValuesContent = await enumerationValues.json();
        } catch (error) {
            message.error("Error while fetching profiles!");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchKontakt().then();

    }, []);


    const handleBackButtonClick = (auftragID) => {
        formFormular.validateFields(['KontakttypV', 'Kontaktname'])
            .then((values) => {

                // Both fields are filled, log the values and navigate to the /kontakte URL
                if (values.KontakttypV === undefined || values.Kontaktname === undefined || values.Kontaktname === '') {
                    // Initialize highlightedFields array
                    const newHighlightedFields = [];

                    // Check and add Kontakttyp to highlightedFields if its value is undefined
                    if (values.KontakttypV === undefined) {
                        newHighlightedFields.push('KontakttypV');
                    }

                    // Check and add Kontaktname to highlightedFields if its value is undefined
                    if (values.Kontaktname === undefined || values.Kontaktname === '') {
                        newHighlightedFields.push('Kontaktname');
                    }

                    // Set the new highlightedFields
                    setHighlightedFields(newHighlightedFields);

                    notification.error({
                        message: 'Validation Error',
                        description: 'Bitte Pflichtfelder ausfüllen.',
                    });
                } else {
                    if(auftragID > 0) {
                        // Both fields are filled, navigate back to Auftrag
                        navigate("/auftrag/"+auftragID+"#kontaktliste");
                    } else {
                        // Both fields are filled, navigate to the /kontakte URL
                        navigate("/kontakte");
                    }
                }
            }).catch((errorInfo) => {
                console.log(errorInfo);
            });
    };

    return (
        <div style={{ padding: '20px' }} className={`lg:w-[84%] w-[90%] ml-auto mr-[2%] rounded-[30px]  ${isSwitchOn ? 'bg-black' : 'bg-white'}`}>
            <h2 className={`font-bold mb-[20px] text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Kontakt bearbeiten</h2>
            {saveIcon1 && <SaveIcon saveIcon={saveIcon} />}
            {auftragID == null ? (
                <Link
                    type="primary"
                    onClick={handleBackButtonClick}
                    icon={<ArrowLeftOutlined />} className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  mt-[10px] p-[10px] rounded-full LinkHover`}>
                    Zurück zur Übersicht
                </Link>
                ) : (
                <Link
                        type="primary"
                        onClick={() => handleBackButtonClick(auftragID)}
                    icon={<ArrowLeftOutlined />} className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  mt-[10px] p-[10px] rounded-full LinkHover`}>
                    Zurück zum Auftrag
                </Link>
                )}
            <div className="flex  justify-center mt-[30px]">

                {dataContent && !isLoading && ( // Bedingung überprüft, ob dataContent bereits vorhanden ist
                    <Form
                        form={formFormular}
                        onBlur={(e) => {
                            // Hier wird das onBlur-Ereignis für das gesamte Formular abgefangen
                            if (e.target.id == "KontakttypV" || e.target.id == "AnredeV" || e.target.id == "TitelV") {
                                updateDataContentValue = selectedOption;
                            } else {
                                updateDataContentValue = e.target.value;
                            }

                            updateDataContent(e.target.id, updateDataContentValue, dataContent);
                            setSaveIcon(true);
                            setSaveIcon1(true)
                            console.log(e.target.id, updateDataContentValue, dataContent);
                            fetch(`${getAPIurl()}/helper/acl/kontakte/${kontaktID}`, {
                                method: 'PUT',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${getToken()}`,
                                },
                                body: JSON.stringify(dataContent),
                            })
                                .then((response) => {
                                    if (!response.ok) {
                                        throw new Error('Failed to update data.');
                                    }
                                    return response.json();

                                })
                                .then((responseData) => {
                                    // Handle the response data after the update
                                    //console.log('Updated data:', responseData);
                                    // Setze das Speicher-Icon nach X Millisekunden zurück
                                    setTimeout(() => { setSaveIcon(false); }, 300);
                                    setTimeout(() => {
                                        setSaveIcon1(false);
                                    }, 3000);
                                })
                                .catch((error) => {
                                    console.error('Error:', error);
                                });

                        }}
                        initialValues={getInitialValuesFormular(dataContent)}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        className=" kONtAK"
                    >
                        <div className={`${isSwitchOn ? 'maxColor' : ''} max-w-[600px] inputsSection`}>
                            <Form.Item label="Kontakttyp" name="KontakttypV"
                                validateStatus={highlightedFields.includes('KontakttypV') ? 'error' : ''}
                                help={highlightedFields.includes('KontakttypV') ? 'Bitte Kontakttyp auswählen' : null}>
                                {/* <Select onChange={handleSelectChange}>
                                {enumerationValuesContent.Kontakttyp.map((option, index) => (
                                    <Option key={index} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select> */}
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.Kontakttyp?.map((option, index) => ({ value: option, key: index }))}
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Kontakttyp"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>
                            </Form.Item>
                            <Form.Item label="Anrede" name="AnredeV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.Anrede?.map((option, index) => ({ value: option, key: index }))}
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange} // Add your change handler if needed
                                >
                                    <TextArea
                                        placeholder="Anrede"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>
                            </Form.Item>
                            <Form.Item label="Titel" name="TitelV">
                                <AutoComplete
                                    className={"AutoCompleteTextArea"}
                                    options={enumerationValuesContent.Titel?.map((option, index) => ({ value: option, key: index }))}
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                    onChange={handleSelectChange}
                                >
                                    <TextArea
                                        placeholder="Titel"
                                        autoSize={true}
                                        className="textInput"
                                    />
                                </AutoComplete>
                            </Form.Item>
                            <Form.Item label="Kontaktname" name="Kontaktname"
                                validateStatus={highlightedFields.includes('Kontaktname') ? 'error' : ''}
                                help={highlightedFields.includes('Kontaktname') ? 'Bitte Kontaktname eingeben' : null}>
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Unternehmen" name="Unternehmen">
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Kontaktabteilung" name="Kontaktabteilung">
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Berufsbezeichnung" name="Berufsbezeichnung">
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Vornamen" name="Vornamen">
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Nachname" name="Nachname">
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Postleitzahl" name="Postleitzahl">
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Ort" name="Ort">
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Strasse" name="Strasse">
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Hausnummer" name="Hausnummer">
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Telefon" name="Telefon">
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Fax" name="Fax">
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Mail" name="Mail" rules={[
                                {
                                    type: 'email',
                                    message: 'Bitte eine gültige E-Mail-Adresse eingeben',
                                }
                            ]}>
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Weblink" name="Weblink">
                                <Input className="inputsAll" />
                            </Form.Item>
                            <Form.Item label="Mobil" name="Mobil">
                                <Input className="inputsAll" />
                            </Form.Item>
                        </div>
                        <div className={`txtSection ${isSwitchOn ? 'maxColor' : ''}`}>
                            <Form.Item label="Anmerkungen" name="Anmerkungen">
                                <TextArea className="textBorder" autoSize={true} rows={4} />
                            </Form.Item>
                        </div>

                    </Form>
                )}
            </div>
        </div>
    );
};

export default KontaktDetails;