import {
    Button,
    Card,
    Col,
    message,
    Row,
    Anchor,
    Form,
    Upload,
    Modal, Select
} from "antd";
import React, { useEffect, useState } from "react";
import { AutoComplete } from "antd";


import { getToken } from "../../helpers";
import {
    getAPIurl,
    getInitialValuesFormular, isTextFieldActive, LoadingScreen,
    locationSearch, openS3File, optimizedHandleScroll, SaveIcon,
    setInitialUploadForms,
    updateDataContent,
    uploadFile
} from "../../helpers/formHelpers";
import { useParams } from "react-router-dom";
import { DeleteOutlined, ExportOutlined, UploadOutlined } from "@ant-design/icons";


import useScreenSize1 from "../../hooks/useScreenSize1";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useSwitch } from '../../pages/SwitchContext';
import { RxCross2 } from "react-icons/rx";
import TextArea from "antd/es/input/TextArea";
// import { Link } from "react-router-dom";
// import { HashLink as Link } from "react-router-hash-link";
const { Dragger } = Upload;
const { Link } = Anchor;
const { Option } = Select;
let dataContainer;
let dataContent;
let enumerationValuesContent;
let updateDataContentValue;
let updateDataContentContainer;
let currentFileChanged;
let currentFileChangedID;
let currentFileChangedComponent;
const putBody = {};


const Anlagen = () => {
    const [vis, setVis] = useState(false);
    const { isDesktopView } = useScreenSize1();
    const { isSwitchOn, toggleSwitch } = useSwitch();
    useEffect(() => {
        if (isDesktopView) {
            setVis(true);
        } else {
            setVis(false);
        }
    }, [isDesktopView]);
    const handleVisibilityChange = (newVisibility) => {
        setVis(newVisibility);
    };

    return (
        <>
            {/* <Row>
            <Col md={4} lg={4} sm={24} xs={24}>
                
            </Col>
            <Col md={20} lg={20} sm={24} xs={24} className="FormContainer">
                
            </Col>
        </Row> */}
            <div className="w-full">
                <div
                    className={`${!isDesktopView ? "visible  cursor-pointer" : "hidden"
                        } text-white pl-[15px]`}
                    onClick={() => {
                        setVis(!vis);
                    }}
                >
                    <button className="menuBtn">Menu</button>
                </div>
                <div
                    className={`flex flex-row gap-[30px] relative mr-[15px]  mb-[40px] ${!isDesktopView ? "ml-[15px]" : ""
                        }`}
                >
                    {vis ? (
                        <div className="w-[299px] absolute xl:relative z-10 h-full xl:h-auto ">
                            <MenuContainer
                                vis={vis}
                                onVisibilityChange={handleVisibilityChange}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    <div className={`w-full ${isSwitchOn ? 'bg-black' : 'bg-white'} rounded-[30px]`}>
                        <FormContainer />
                    </div>
                </div>
            </div>
        </>

    );
};

const MenuContainer = ({ vis, onVisibilityChange }) => {
    window.addEventListener('scroll', optimizedHandleScroll);
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const [isInLower20, setIsInLower20] = useState(false);
    const handleClick = () => {
        const newVisibility = !vis;
        onVisibilityChange(newVisibility);
    };
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            // Check if the device height is less than 600px
            const isDeviceHeightBelow600 = window.innerHeight < 650;

            // Calculate the scroll position percentage
            const scrollPercentage = (scrollPosition / (documentHeight - windowHeight)) * 100;

            // Set the state based on the scroll position and device height
            setIsInLower20(isDeviceHeightBelow600 && scrollPercentage >= 80);
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className={`sidebar rounded-r-[30px] ${isSwitchOn ? 'bg-black' : 'bg-white'}`}>
            <div className={`ml-[197px]`}><RxCross2 onClick={handleClick} className={`xl:hidden ${isSwitchOn ? 'text-white' : 'text-black'} text-[30px] mb-[20px] `} /></div>
            <Anchor affix={false} offsetTop={80} className={`your-component ${isInLower20 ? 'lower-20' : ''}`}>
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#anl_bilder" title="Bilddokumentation" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#karte" title="Karte" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#flurkarte" title="Flurkarte" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#grundbuchauszug" title="Grundbuchauszug" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#bauplan" title="Bauplan" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#flaechen" title="Flächen" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#sonstige-anlagen" title="Sonstige Anlagen" />

            </Anchor>
        </div >
    );
};


const FormContainer = () => {

    const [cards, setCards] = useState([]);
    const [loading, setIsLoading] = useState(false);
    const [saveIcon, setSaveIcon] = useState(false);
    const [saveIcon1, setSaveIcon1] = useState(false);
    const [formFormular1] = Form.useForm();
    const { auftragID, gutachtenID, tab } = useParams(); // Get the activeKey from the URL
    const [selectedOption, setSelectedOption] = useState(null);
    const { isSwitchOn, toggleSwitch } = useSwitch();

    const [uploadForms, setUploadForms] = useState([]);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const updatedUploadForms = [...uploadForms];
    const [initialValues, setInitialValuesState] = useState([]);

    const handleSelectChange = (value) => {
        setSelectedOption(value);
    };

    const fetchAnlagen = async (jumptoanchor=true) => {
        setIsLoading(true);

        try {
            //let cardsArray = {};
            const response = await fetch(`${getAPIurl()}/helper/acl/gutachten/${gutachtenID}?populate=deep`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            dataContainer = await response.json();
            dataContent = dataContainer;
            console.log(dataContent);
            setInitialValuesState(getInitialValuesFormular(dataContent));
            const enumerationValues = await fetch(`${getAPIurl()}/gutachten/enumvalues`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            enumerationValuesContent = await enumerationValues.json();

            setCards(dataContent.Anlagen);
            //  console.log(...cards);
            //console.log(dataContent.Anlagen);

            setUploadForms(setInitialUploadForms(updatedUploadForms, dataContent.Anlagen.ANL_Bilder, "ANL_Bilder", false));
            setUploadForms(setInitialUploadForms(updatedUploadForms, dataContent.Anlagen.Karte, "Karte", false));
            setUploadForms(setInitialUploadForms(updatedUploadForms, dataContent.Anlagen.Flurkarte, "Flurkarte", false));
            setUploadForms(setInitialUploadForms(updatedUploadForms, dataContent.Anlagen.Grundbuchauszug, "Grundbuchauszug", false));
            setUploadForms(setInitialUploadForms(updatedUploadForms, dataContent.Anlagen.Bauplan, "Bauplan", false));
            setUploadForms(setInitialUploadForms(updatedUploadForms, dataContent.Anlagen.Flaechen, "Flaechen", false));
            setUploadForms(setInitialUploadForms(updatedUploadForms, dataContent.Anlagen.SonstigeAnlagen, "SonstigeAnlagen", false));

        } catch (error) {
            console.log(error);
            message.error("Error while fetching profiles!" + error);
        } finally {
            //When everything is loaded jump to anchor (if selected)
            let currentUrl = window.location.href.split("#");

            if(currentUrl[1] && jumptoanchor == true){ // Anchor(=Hashtag) is in URL
                document.querySelector('a[href="#'+currentUrl[1]+'"]').click();
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if(saveIcon){
            fetchAnlagen(false).then(); //No jump to anchor if an update of data triggers reload of data
        } else {
            fetchAnlagen().then();
        }

        const interval = setInterval(() => {
            if(!isTextFieldActive()) { //Not if currently in a textfield
                fetchAnlagen(false).then();
            }
        }, 30000); // Every 30 Seconds (in case multiple persons are working OR multiple tabs)
        return () => clearInterval(interval);

    }, [updateTrigger]);

    const addCard = (component, uploadForm = false) => {

        const newCard = {};
        if(component == "ANL_Bilder"){
            document.getElementById("button_new_component_Anlagen__ANL_Bilder").hidden = true; // Button "Neue Komponente" verstecken, da nur eins im Status "undefined" existieren darf
        } else {
            document.getElementById("button_new_component_" + component).hidden = true; // Button "Neue Komponente" verstecken, da nur eins im Status "undefined" existieren darf
        }


        if (uploadForm == true) {
            //Upload Area for new Card
            const updatedUploadFormsAdd = uploadForms;
            updatedUploadFormsAdd[component][undefined] = {};
            updatedUploadFormsAdd[component][undefined].file = "";
            updatedUploadFormsAdd[component][undefined].visible = true;
            setUploadForms(updatedUploadFormsAdd);
        }

        setCards({ ...cards, [component]: [...cards[component], newCard] });
    };
    const deleteCard = (component, index, id) => {
        Modal.confirm({
            title: 'Komponente löschen',
            content: 'Möchten Sie diesen Anlage wirklich löschen?',
            onOk() {
                deleteCardConfirmed(component, index, id);
            },
            onCancel() {
                console.log('Löschung abgebrochen');
            },
        });
    };

    const deleteCardConfirmed = (component, index, id) => {
        const currentComponent = dataContent.Anlagen[component][index];
        for (const key in currentComponent) {
            if (currentComponent.hasOwnProperty(key)) {
               if(typeof currentComponent[key] == "object" && currentComponent[key] != null  && currentComponent[key].id){
                   handleRemoveAttachmentConfirmed("", currentComponent[key].id).then();
               }
            }
        }
        const updatedCardsComponent = [...cards[component]];
        updatedCardsComponent.splice(index, 1); // Remove the card at the specified index
        cards[component] = updatedCardsComponent;
        setCards(cards);
        //console.log(dataContent.Anlagen);
        //console.log(dataContent.Anlagen[component]);
        updateDataContent(component, index, dataContent, "deleteComponent");
        putBody.data = dataContent;
        updateGutachten(putBody, false);
    };

    const updateGutachten = (putBody, forceReload = false, forcePageReload = false) => {
        if (forcePageReload) {
            setIsLoading(true);
        } else {
            setSaveIcon(true);
            setSaveIcon1(true)
        }
        fetch(`${getAPIurl()}/helper/acl/gutachten/${gutachtenID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(putBody.data),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to update data.');
                }

                return response.json();
            })
            .then((responseData) => {
                // New component (undefined item.id) => reload;
                if (forceReload) {
                    if (forcePageReload == true) {
                        // window.location.reload(); // This should be eliminated, just temporarly
                    } else {
                        setUpdateTrigger((prev) => !prev);
                        setIsLoading(false);
                    }
                }

                // Setze das Speicher-Icon nach X Millisekunden zurück
                setTimeout(() => { setSaveIcon(false); }, 300);
                setTimeout(() => {
                    setSaveIcon1(false);
                }, 3000);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const customUploadFile = async (file) => {

        try {
            if (file) {
                const responseUF = await uploadFile(file, true, auftragID);
                // Check if the response is an array
                updateDataContentValue = {}
                console.log(responseUF);
                if (Array.isArray(responseUF)) {
                    // Use forEach to iterate through the array (normally 1, because multiple files are not allowed)
                    responseUF.forEach((item, index) => {
                        updateDataContentValue["url"] = item.hash + item.ext;
                        updateDataContentValue["id"] = item.id;
                        updateDataContentValue["name"] = item.name;
                    });
                }
                if (Object.keys(updateDataContentValue).length === 0) {
                    updateDataContentValue = "-";
                }
                // Save changes in database
                updateDataContentContainer = dataContent;
                updateDataContent(currentFileChanged, updateDataContentValue, updateDataContentContainer, "updateByID");
                putBody.data = dataContent;
                updateGutachten(putBody, true, false);
                const elements = document.getElementsByClassName("button_new_component");
                for (let i = 0; i < elements.length; i++) {
                    elements[i].hidden = false;
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    /*function setInitialUploadForms(files,name){
        let file_container;
        updatedUploadForms[name] = {};
        for (const key in files) {
            if (files.hasOwnProperty(key)) {
                const file = files[key];
                for(const key_file in file){
                    if (file.hasOwnProperty(key_file)) {
                         const value = file[key_file];

                         if(typeof value === "object" && value !== null){
                             file_container = value;
                         }
                    }
                }
                updatedUploadForms[name][file.id] = {}
                if(file_container){
                    updatedUploadForms[name][file.id].file = file_container;
                    updatedUploadForms[name][file.id].visible = false;
                } else {
                    updatedUploadForms[name][file.id].file = "";
                    updatedUploadForms[name][file.id].visible = true;
                }
                file_container = undefined;
            }
        }

        setUploadForms(updatedUploadForms);

    }*/
    const handleRemoveAttachment = (dataContentEntry, file) => {
        Modal.confirm({
            title: 'Datei löschen',
            content: 'Möchten Sie die Datei wirklich löschen?',
            onOk() {
                handleRemoveAttachmentConfirmed(dataContentEntry, file);
            },
            onCancel() {
                console.log('Löschung abgebrochen');
            },
        });
    };

    async function handleRemoveAttachmentConfirmed(dataContentEntry, file) {
        try {
            const response = await fetch(`${getAPIurl()}/helper/acl/upload/${file}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });

            if (response.ok) {
                const responseData = await response.json();

            } else {
                console.log(`Request failed with status ${response.status}`);
            }
        } catch (error) {
            return error;
        }
        if(dataContentEntry != ""){
            updateDataContentContainer = dataContent;
            updateDataContent(dataContentEntry, "-", updateDataContentContainer, "updateByID");
            putBody.data = dataContent;
            updateGutachten(putBody, true, false);
        }
    }

    const FileChanged = (name, id, component) => {
        currentFileChanged = name;
        currentFileChangedID = id; //Für später (löschen)
        currentFileChangedComponent = component; // Keine Ahnungs obs gebraucht wird
    };
    useEffect(() => formFormular1.resetFields(), [initialValues]);
    return (
        <div className={`${isSwitchOn ? 'bg-black' : 'bg-white'} sm:p-[20px] p-[10px] rounded-[30px]`}>
            {loading && <LoadingScreen />}
            {saveIcon1 && <SaveIcon saveIcon={saveIcon} />}
            <h2 className={`${isSwitchOn ? 'text-white' : 'text-black'} font-bold text-16px  md:text-[28px] mb-[20px] generalText`}>Anlagen</h2>
            <div>
                {dataContent && initialValues && enumerationValuesContent && ( // Bedingung überprüft, ob dataContent bereits vorhanden ist
                    <Form
                        form={formFormular1}
                        onBlur={(e) => {
                            // Hier wird das onBlur-Ereignis für das gesamte Formular abgefangen
                            if (e.target.type != "button") { //Not at addCard
                                updateDataContentContainer = dataContent;
                                //const patternTeilnehmerArt = /^AllAng__Ortstermin__\d+__TeilnehmerArt$/;
                                if (e.target.type == "search") {
                                    updateDataContentValue = selectedOption;
                                    setSelectedOption(null); //Reset (for the case the next select is choosen without value)
                                } else {
                                    updateDataContentValue = e.target.value;
                                }
                                const updateByIDArray = ["Anlagen__ANL_Bilder", "Karte", "Flurkarte", "Grundbuchauszug", "Bauplan", "Flaechen", "SonstigeAnlagen"];
                                const foundComponent = updateByIDArray.find(value => e.target.id.includes(value));
                                if (e.target.id && foundComponent) {
                                    updateDataContent(e.target.id, updateDataContentValue, updateDataContentContainer, "updateByID");
                                } else {
                                    updateDataContent(e.target.id, updateDataContentValue, updateDataContentContainer, "updateByIndex");
                                }
                                putBody.data = dataContent;
                                if (e.target.id.includes("undefined")) {
                                    updateGutachten(putBody, true, false);
                                    const elements = document.getElementsByClassName("button_new_component");
                                    for (let i = 0; i < elements.length; i++) {
                                        elements[i].hidden = false;
                                    }
                                } else {
                                    updateGutachten(putBody, false);
                                }
                            }
                        }}
                        initialValues={initialValues}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                    // style={{ maxWidth: 800 }}
                    >
                        <div id="anl_bilder" className="scrollable-section ">
                            <div
                                className="flex justify-between items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Bilddokumentation</h3>

                            </div>

                            {cards?.ANL_Bilder?.map((entry, index) => (
                                <Card className={`pl-[3%] md:pl-[10%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                      size="small" key={index}>
                                    <div className={`flex   anlagen ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Kategorie:`}
                                                   name={`Anlagen__ANL_Bilder__${entry.id}__Bild_KategorieV`}>
                                            <AutoComplete
                                                className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                options={enumerationValuesContent?.sub_com_Bild_Kategorie?.map(
                                                    (option, index) => ({value: option, key: index})
                                                )}
                                                filterOption={(inputValue, option) =>
                                                    option.value
                                                        .toUpperCase()
                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={handleSelectChange} // Add your change handler if needed
                                            >
                                                <TextArea
                                                    placeholder="Kategorie"
                                                    autoSize={true}
                                                    className="textInput"
                                                />
                                            </AutoComplete>

                                        </Form.Item>
                                        <div className="ml-[10px] gap-[10px] flex">
                                            <div className=" ml-0  md:mt-[0]" style={{cursor: 'pointer'}}
                                                 onClick={() => deleteCard("ANL_Bilder", index, entry.id)}>
                                                <RiDeleteBin5Fill className="text-[#FF2E3B] w-[18px] h-[20px]"/>
                                            </div>
                                        </div>
                                    </div>


                                    <Form.Item label="Bild" name={`Anlagen__ANL_Bilder__${entry.id}__Bild`}
                                               className={`max-content4 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        {uploadForms["ANL_Bilder"][entry.id] && uploadForms["ANL_Bilder"][entry.id].visible ? (
                                            <Upload.Dragger customRequest={customUploadFile}
                                                            onChange={(info) => FileChanged(`Anlagen__ANL_Bilder__${entry.id}__Bild`, entry.id, "ANL_Bilder")}
                                                            accept="image/jpg,image/jpeg,image/png">
                                                <p className="ant-upload-drag-icon">
                                                    <UploadOutlined/>
                                                </p>
                                                <p className="ant-upload-text">Klicke oder ziehe Dateien hierhin, um sie
                                                    hochzuladen</p>
                                                <p className="ant-upload-hint">Unterstützte Dateiformate: .jpg, .png</p>
                                            </Upload.Dragger>
                                        ) : uploadForms["ANL_Bilder"][entry.id] !== undefined ? (
                                            <>
                                                <div>
                                                    <Button icon={<ExportOutlined/>}
                                                            onClick={() => openS3File(uploadForms["ANL_Bilder"][entry.id].file.url)}>{uploadForms["ANL_Bilder"][entry.id].file.name}</Button>
                                                    <Button icon={<DeleteOutlined/>}
                                                            onClick={() => handleRemoveAttachment(`Anlagen__ANL_Bilder__${entry.id}__Bild`, uploadForms["ANL_Bilder"][entry.id].file.id)}>
                                                        Löschen
                                                    </Button>
                                                </div>
                                            </>
                                        ) : null}
                                    </Form.Item>
                                    <div className={`allgemeineClass1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Lage:`}
                                                   name={`Anlagen__ANL_Bilder__${entry.id}__Bild_LageV`}>
                                            <AutoComplete
                                                className={"AutoCompleteTextArea"}
                                                options={enumerationValuesContent.sub_com_Bild_Lage?.map(
                                                    (option, index) => ({value: option, key: index})
                                                )}
                                                filterOption={(inputValue, option) =>
                                                    option.value
                                                        .toUpperCase()
                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={handleSelectChange} // Add your change handler if needed
                                            >
                                                <TextArea
                                                    placeholder="Lage"
                                                    autoSize={true}
                                                    className="textInput"
                                                />
                                            </AutoComplete>

                                        </Form.Item>
                                        <Form.Item label={`Anmerkungen:`}
                                                   name={`Anlagen__ANL_Bilder__${entry.id}__Bild_AnmerkungenV`}>
                                            <AutoComplete
                                                className={"AutoCompleteTextArea"}
                                                options={enumerationValuesContent.sub_com_Bild_Anmerkungen?.map(
                                                    (option, index) => ({value: option, key: index})
                                                )}
                                                filterOption={(inputValue, option) =>
                                                    option.value
                                                        .toUpperCase()
                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={handleSelectChange} // Add your change handler if needed
                                            >
                                                <TextArea
                                                    placeholder="Anmerkungen"
                                                    autoSize={true}
                                                    className="textInput"
                                                />
                                            </AutoComplete>

                                        </Form.Item>
                                    </div>
                                </Card>
                            ))}
                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[12px] md:text-[14px] rounded-[30px] m-[20px] button_new_component border-none `}
                                    onClick={() => addCard("ANL_Bilder", true)} id={"button_new_component_Anlagen__ANL_Bilder"}>Neues
                                    Bild</Button>
                            </div>

                        </div>
                        <div id="karte" className="scrollable-section border-t border-gray-300">
                            <div
                                className="flex justify-between items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Karte</h3>

                            </div>

                            {cards.Karte?.map((entry, index) => (
                                <Card className={`pl-[3%] md:pl-[10%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                      size="small" key={index}>
                                    <div className={`flex  anlagen ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Kategorie`} name={`Anlagen__Karte__${entry.id}__Karte_DDV`}>
                                            <AutoComplete
                                                className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                options={enumerationValuesContent.sub_com_Karte_DD?.map(
                                                    (option, index) => ({value: option, key: index})
                                                )}
                                                filterOption={(inputValue, option) =>
                                                    option.value
                                                        .toUpperCase()
                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={handleSelectChange} // Add your change handler if needed
                                            >
                                                <TextArea
                                                    placeholder="Kategorie"
                                                    autoSize={true}
                                                    className="textInput"
                                                />
                                            </AutoComplete>

                                        </Form.Item>
                                        <div className="ml-[10px] gap-[10px] flex">
                                            <div className=" md:mt-[0] mt-[0]"
                                                 style={{float: 'right', cursor: 'pointer'}}
                                                 onClick={() => deleteCard("Karte", index, entry.id)}>
                                                <RiDeleteBin5Fill className="text-[#FF2E3B] w-[18px] h-[20px]"/>
                                            </div>
                                        </div>
                                    </div>


                                    <Form.Item label="Datei" name={`Anlagen__Karte__${entry.id}__Karte_Doc`}
                                               className={`max-content4 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        {uploadForms["Karte"][entry.id] && uploadForms["Karte"][entry.id].visible ? (
                                            <Upload.Dragger customRequest={customUploadFile}
                                                            onChange={(info) => FileChanged(`Anlagen__Karte__${entry.id}__Karte_Doc`, entry.id, "Karte")}
                                                            accept="image/jpg,image/jpeg,image/png">
                                                <p className="ant-upload-drag-icon">
                                                    <UploadOutlined/>
                                                </p>
                                                <p className="ant-upload-text">Klicke oder ziehe Dateien hierhin, um sie
                                                    hochzuladen</p>
                                                <p className="ant-upload-hint">Unterstützte Dateiformate: .jpg, .png</p>
                                            </Upload.Dragger>
                                        ) : uploadForms["Karte"][entry.id] !== undefined ? (
                                            <>
                                                <div className="gap-[10px]">
                                                    <Button icon={<ExportOutlined/>}
                                                            onClick={() => openS3File(uploadForms["Karte"][entry.id].file.url)}>{uploadForms["Karte"][entry.id].file.name}</Button>
                                                    <Button icon={<DeleteOutlined/>}
                                                            onClick={() => handleRemoveAttachment(`Anlagen__Karte__${entry.id}__Karte_Doc`, uploadForms["Karte"][entry.id].file.id)}>
                                                        Löschen
                                                    </Button>
                                                </div>
                                            </>
                                        ) : null}
                                    </Form.Item>
                                </Card>
                            ))}
                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'} border-none text-[12px] md:text-[14px] m-[20px] rounded-[30px] border-none1 button_new_component`}
                                    onClick={() => addCard("Karte", true)} id={"button_new_component_Karte"}>Neue
                                    Karte</Button>
                            </div>
                        </div>
                        <div id="flurkarte" className="scrollable-section border-t border-gray-300">
                            <div
                                className="flex justify-between items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Flurkarte</h3>

                            </div>

                            {cards.Flurkarte && Array.isArray(cards?.Flurkarte) && cards.Flurkarte?.map((entry, index) => (
                                <Card className={`pl-[3%] md:pl-[10%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                      size="small" key={index}>
                                    <div className={`flex  anlagen ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Kategorie`}
                                                   name={`Anlagen__Flurkarte__${entry.id}__Flurkarte_DDV`}>
                                            <AutoComplete
                                                className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                options={enumerationValuesContent.sub_com_Flurkarte_DD?.map(
                                                    (option, index) => ({value: option, key: index})
                                                )}
                                                filterOption={(inputValue, option) =>
                                                    option.value
                                                        .toUpperCase()
                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={handleSelectChange} // Add your change handler if needed
                                            >
                                                <TextArea
                                                    placeholder="Kategorie"
                                                    autoSize={true}
                                                    className="textInput"
                                                />
                                            </AutoComplete>

                                        </Form.Item>
                                        <div className="ml-[10px] gap-[10px] flex">
                                            <div className=" md:mt-[0] mt-[]"
                                                 style={{float: 'right', cursor: 'pointer'}}
                                                 onClick={() => deleteCard("Flurkarte", index, entry.id)}>
                                                <RiDeleteBin5Fill className="text-[#FF2E3B] w-[18px] h-[20px]"/>
                                            </div>
                                        </div>
                                    </div>


                                    <Form.Item label="Datei" name={`Anlagen__Flurkarte__${entry.id}__Flurkarte_Doc`}
                                               className={`max-content4 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        {uploadForms["Flurkarte"][entry.id] && uploadForms["Flurkarte"][entry.id].visible ? (
                                            <Upload.Dragger customRequest={customUploadFile}
                                                            onChange={(info) => FileChanged(`Anlagen__Flurkarte__${entry.id}__Flurkarte_Doc`, entry.id, "Flurkarte")}
                                                            accept="image/jpg,image/jpeg,image/png">
                                                <p className="ant-upload-drag-icon">
                                                    <UploadOutlined/>
                                                </p>
                                                <p className="ant-upload-text">Klicke oder ziehe Dateien hierhin, um sie
                                                    hochzuladen</p>
                                                <p className="ant-upload-hint">Unterstützte Dateiformate: .jpg, .png</p>
                                            </Upload.Dragger>
                                        ) : uploadForms["Flurkarte"][entry.id] !== undefined ? (
                                            <>
                                                <div>
                                                    <Button icon={<ExportOutlined/>}
                                                            onClick={() => openS3File(uploadForms["Flurkarte"][entry.id].file.url)}>{uploadForms["Flurkarte"][entry.id].file.name}</Button>
                                                    <Button icon={<DeleteOutlined/>}
                                                            onClick={() => handleRemoveAttachment(`Anlagen__Flurkarte__${entry.id}__Flurkarte_Doc`, uploadForms["Flurkarte"][entry.id].file.id)}>
                                                        Löschen
                                                    </Button>
                                                </div>
                                            </>
                                        ) : null}
                                    </Form.Item>
                                </Card>
                            ))}
                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[12px] md:text-[14px] text-[#FFFFFF] m-[20px] rounded-[30px] border-none button_new_component`}
                                    onClick={() => addCard("Flurkarte", true)} id={"button_new_component_Flurkarte"}>Neue
                                    Flurkarte</Button>
                            </div>
                        </div>

                        <div id="grundbuchauszug" className="scrollable-section border-t border-gray-300">
                            <div
                                className="flex justify-between items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Grundbuchauszug</h3>

                            </div>

                            {cards?.Grundbuchauszug?.map((entry, index) => (
                                <Card className={`pl-[3%] md:pl-[10%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                      size="small" key={index}>
                                    <div className={`flex  anlagen ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Kategorie`}
                                                   name={`Anlagen__Grundbuchauszug__${entry.id}__GBA_DDV`}>
                                            <AutoComplete
                                                className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                options={enumerationValuesContent?.sub_com_GBA_DD?.map(
                                                    (option, index) => ({value: option, key: index})
                                                )}
                                                filterOption={(inputValue, option) =>
                                                    option.value
                                                        .toUpperCase()
                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={handleSelectChange} // Add your change handler if needed
                                            >
                                                <TextArea
                                                    placeholder="Kategorie"
                                                    autoSize={true}
                                                    className="textInput"
                                                />
                                            </AutoComplete>

                                        </Form.Item>
                                        <div className="ml-[10px] gap-[10px] flex">
                                            <div className=" md:mt-[0] mt-[0]"
                                                 style={{float: 'right', cursor: 'pointer'}}
                                                 onClick={() => deleteCard("Grundbuchauszug", index, entry.id)}>
                                                <RiDeleteBin5Fill className="text-[#FF2E3B] w-[18px] h-[20px]"/>
                                            </div>
                                        </div>
                                    </div>


                                    <Form.Item label="Datei" name={`Anlagen__Grundbuchauszug__${entry.id}__GBA_Doc`}
                                               className={`max-content4 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        {uploadForms["Grundbuchauszug"][entry.id] && uploadForms["Grundbuchauszug"][entry.id].visible ? (
                                            <Upload.Dragger customRequest={customUploadFile}
                                                            onChange={(info) => FileChanged(`Anlagen__Grundbuchauszug__${entry.id}__GBA_Doc`, entry.id, "Grundbuchauszug")}
                                                            accept="image/jpg,image/jpeg,image/png">
                                                <p className="ant-upload-drag-icon">
                                                    <UploadOutlined/>
                                                </p>
                                                <p className="ant-upload-text">Klicke oder ziehe Dateien hierhin, um sie
                                                    hochzuladen</p>
                                                <p className="ant-upload-hint">Unterstützte Dateiformate: .jpg, .png</p>
                                            </Upload.Dragger>
                                        ) : uploadForms["Grundbuchauszug"][entry.id] !== undefined ? (
                                            <>
                                                <div>
                                                    <Button icon={<ExportOutlined/>}
                                                            onClick={() => openS3File(uploadForms["Grundbuchauszug"][entry.id].file.url)}>{uploadForms["Grundbuchauszug"][entry.id].file.name}</Button>
                                                    <Button icon={<DeleteOutlined/>}
                                                            onClick={() => handleRemoveAttachment(`Anlagen__Grundbuchauszug__${entry.id}__GBA_Doc`, uploadForms["Grundbuchauszug"][entry.id].file.id)}>
                                                        Löschen
                                                    </Button>
                                                </div>
                                            </>
                                        ) : null}
                                    </Form.Item>
                                </Card>
                            ))}
                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[12px] md:text-[14px] m-[20px] rounded-[30px] border-none button_new_component`}
                                    onClick={() => addCard("Grundbuchauszug", true)}
                                    id={"button_new_component_Grundbuchauszug"}>Neuer Grundbuchauszug</Button>
                            </div>
                        </div>
                        <div id="bauplan" className="scrollable-section border-t border-gray-300">
                            <div
                                className="flex justify-between items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Bauplan</h3>

                            </div>

                            {cards?.Bauplan?.map((entry, index) => (
                                <Card className={`pl-[3%] md:pl-[10%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                      size="small" key={index}>
                                    <div className={`flex  anlagen ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Kategorie`}
                                                   name={`Anlagen__Bauplan__${entry.id}__Bauplan_DDV`}>
                                            <AutoComplete
                                                className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                options={enumerationValuesContent?.sub_com_Bauplan_DD?.map(
                                                    (option, index) => ({value: option, key: index})
                                                )}
                                                filterOption={(inputValue, option) =>
                                                    option.value
                                                        .toUpperCase()
                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={handleSelectChange} // Add your change handler if needed
                                            >
                                                <TextArea
                                                    placeholder="Kategorie"
                                                    autoSize={true}
                                                    className="textInput"
                                                />
                                            </AutoComplete>

                                        </Form.Item>
                                        <div className="ml-[10px] gap-[10px] flex">
                                            <div className=" md:mt-[0] mt-[0]"
                                                 style={{float: 'right', cursor: 'pointer'}}
                                                 onClick={() => deleteCard("Bauplan", index, entry.id)}>
                                                <RiDeleteBin5Fill className="text-[#FF2E3B] w-[18px] h-[20px]"/>
                                            </div>
                                        </div>
                                    </div>


                                    <Form.Item label="Datei" name={`Anlagen__Bauplan__${entry.id}__Bauplan_Doc`}
                                               className={`max-content4 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        {uploadForms["Bauplan"][entry.id] && uploadForms["Bauplan"][entry.id].visible ? (
                                            <Upload.Dragger customRequest={customUploadFile}
                                                            onChange={(info) => FileChanged(`Anlagen__Bauplan__${entry.id}__Bauplan_Doc`, entry.id, "Bauplan")}
                                                            accept="image/jpg,image/jpeg,image/png">
                                                <p className="ant-upload-drag-icon">
                                                    <UploadOutlined/>
                                                </p>
                                                <p className="ant-upload-text">Klicke oder ziehe Dateien hierhin, um sie
                                                    hochzuladen</p>
                                                <p className="ant-upload-hint">Unterstützte Dateiformate: .jpg, .png</p>
                                            </Upload.Dragger>
                                        ) : uploadForms["Bauplan"][entry.id] !== undefined ? (
                                            <>
                                                <div>
                                                    <Button icon={<ExportOutlined/>}
                                                            onClick={() => openS3File(uploadForms["Bauplan"][entry.id].file.url)}>{uploadForms["Bauplan"][entry.id].file.name}</Button>
                                                    <Button icon={<DeleteOutlined/>}
                                                            onClick={() => handleRemoveAttachment(`Anlagen__Bauplan__${entry.id}__Bauplan_Doc`, uploadForms["Bauplan"][entry.id].file.id)}>
                                                        Löschen
                                                    </Button>
                                                </div>
                                            </>
                                        ) : null}
                                    </Form.Item>
                                </Card>
                            ))}
                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[12px] md:text-[14px] m-[20px] rounded-[30px] border-none button_new_component`}
                                    onClick={() => addCard("Bauplan", true)} id={"button_new_component_Bauplan"}>Neuer
                                    Bauplan</Button>
                            </div>
                        </div>
                        <div id="flaechen" className="scrollable-section border-t border-gray-300">
                            <div
                                className="flex justify-between items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Flächen</h3>

                            </div>

                            {cards?.Flaechen?.map((entry, index) => (
                                <Card className={`pl-[3%] md:pl-[10%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                      size="small" key={index}>
                                    <div className={`flex  anlagen ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Kategorie`}
                                                   name={`Anlagen__Flaechen__${entry.id}__Flaechen_DDV`}>
                                            <AutoComplete
                                                className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                options={enumerationValuesContent?.sub_com_Flaechen_DD?.map(
                                                    (option, index) => ({value: option, key: index})
                                                )}
                                                filterOption={(inputValue, option) =>
                                                    option.value
                                                        .toUpperCase()
                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={handleSelectChange} // Add your change handler if needed
                                            >
                                                <TextArea
                                                    placeholder="Kategorie"
                                                    autoSize={true}
                                                    className="textInput"
                                                />
                                            </AutoComplete>

                                        </Form.Item>
                                        <div className="ml-[10px] gap-[10px] flex">
                                            <div className=" md:mt-[0] " style={{float: 'right', cursor: 'pointer'}}
                                                 onClick={() => deleteCard("Flaechen", index, entry.id)}>
                                                <DeleteOutlined className="text-[#FF2E3B]"/>
                                            </div>
                                        </div>
                                    </div>


                                    <Form.Item label="Datei" name={`Anlagen__Flaechen__${entry.id}__Flaechen_Doc`}
                                               className={`max-content4 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        {uploadForms["Flaechen"][entry.id] && uploadForms["Flaechen"][entry.id].visible ? (
                                            <Upload.Dragger customRequest={customUploadFile}
                                                            onChange={(info) => FileChanged(`Anlagen__Flaechen__${entry.id}__Flaechen_Doc`, entry.id, "Flaechen")}
                                                            accept="image/jpg,image/jpeg,image/png">
                                                <p className="ant-upload-drag-icon">
                                                    <UploadOutlined/>
                                                </p>
                                                <p className="ant-upload-text">Klicke oder ziehe Dateien hierhin, um sie
                                                    hochzuladen</p>
                                                <p className="ant-upload-hint">Unterstützte Dateiformate: .jpg, .png</p>
                                            </Upload.Dragger>
                                        ) : uploadForms["Flaechen"][entry.id] !== undefined ? (
                                            <>
                                                <div>
                                                    <Button icon={<ExportOutlined/>}
                                                            onClick={() => openS3File(uploadForms["Flaechen"][entry.id].file.url)}>{uploadForms["Flaechen"][entry.id].file.name}</Button>
                                                    <Button icon={<DeleteOutlined/>}
                                                            onClick={() => handleRemoveAttachment(`Anlagen__Flaechen__${entry.id}__Flaechen_Doc`, uploadForms["Flaechen"][entry.id].file.id)}>
                                                        Löschen
                                                    </Button>
                                                </div>
                                            </>
                                        ) : null}
                                    </Form.Item>
                                </Card>
                            ))}
                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[12px] md:text-[14px] m-[20px] rounded-[30px] border-none button_new_component`}
                                    onClick={() => addCard("Flaechen", true)} id={"button_new_component_Flaechen"}>Neue
                                    Flächendatei</Button>
                            </div>
                        </div>
                        <div id="sonstige-anlagen" className="scrollable-section border-t border-gray-300">
                            <div
                                className="flex justify-between items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Sonstige
                                    Anlagen</h3>

                            </div>

                            {cards?.SonstigeAnlagen?.map((entry, index) => (
                                <Card className={`pl-[3%] md:pl-[10%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                      size="small" key={index}>
                                    <div className={`flex  anlagen ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Kategorie`}
                                                   name={`Anlagen__SonstigeAnlagen__${entry.id}__SonstAnl_DDV`}>
                                            <AutoComplete
                                                className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                options={enumerationValuesContent?.sub_com_SonstAnl_DD?.map(
                                                    (option, index) => ({value: option, key: index})
                                                )}
                                                filterOption={(inputValue, option) =>
                                                    option.value
                                                        .toUpperCase()
                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={handleSelectChange} // Add your change handler if needed
                                            >
                                                <TextArea
                                                    placeholder="Kategorie"
                                                    autoSize={true}
                                                    className="textInput"
                                                />
                                            </AutoComplete>

                                        </Form.Item>
                                        <div className="ml-[10px] gap-[10px] flex">
                                            <div className=" md:mt-[0] mt-[0]"
                                                 style={{float: 'right', cursor: 'pointer'}}
                                                 onClick={() => deleteCard("SonstigeAnlagen", index, entry.id)}>
                                                <RiDeleteBin5Fill className="text-[#FF2E3B] w-[18px] h-[20px]"/>
                                            </div>
                                        </div>
                                    </div>


                                    <Form.Item label="Datei"
                                               name={`Anlagen__SonstigeAnlagen__${entry.id}__SonstAnl_Doc`}
                                               className={`max-content4 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        {uploadForms["SonstigeAnlagen"][entry.id] && uploadForms["SonstigeAnlagen"][entry.id].visible ? (
                                            <Upload.Dragger customRequest={customUploadFile}
                                                            onChange={(info) => FileChanged(`Anlagen__SonstigeAnlagen__${entry.id}__SonstAnl_Doc`, entry.id, "SonstigeAnlagen")}
                                                            accept="image/jpg,image/jpeg,image/png">
                                                <p className="ant-upload-drag-icon">
                                                    <UploadOutlined/>
                                                </p>
                                                <p className="ant-upload-text">Klicke oder ziehe Dateien hierhin, um sie
                                                    hochzuladen</p>
                                                <p className="ant-upload-hint">Unterstützte Dateiformate: .jpg, .png</p>
                                            </Upload.Dragger>
                                        ) : uploadForms["SonstigeAnlagen"][entry.id] !== undefined ? (
                                            <>
                                                <div>
                                                    <Button icon={<ExportOutlined/>}
                                                            onClick={() => openS3File(uploadForms["SonstigeAnlagen"][entry.id].file.url)}>{uploadForms["SonstigeAnlagen"][entry.id].file.name}</Button>
                                                    <Button icon={<DeleteOutlined/>}
                                                            onClick={() => handleRemoveAttachment(`Anlagen__SonstigeAnlagen__${entry.id}__SonstAnl_Doc`, uploadForms["SonstigeAnlagen"][entry.id].file.id)}>
                                                        Löschen
                                                    </Button>
                                                </div>
                                            </>
                                        ) : console.log(uploadForms["SonstigeAnlagen"], entry.id)}
                                    </Form.Item>
                                </Card>
                            ))}
                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[12px] md:text-[14px] m-[20px] rounded-[30px] border-none button_new_component`}
                                    onClick={() => addCard("SonstigeAnlagen", true)}
                                    id={"button_new_component_SonstigeAnlagen"}>Neue sonstige Anlage</Button>
                            </div>
                        </div>
                    </Form>
                )}
            </div>
        </div>
    );
};

export default Anlagen;
