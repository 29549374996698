import React, { useEffect, useState } from "react";
import { Form, Input, Button, Switch, message } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { getToken } from "../helpers";
import {getAPIurl, validatePasswordSecurity} from "../helpers/formHelpers";
import { useSwitch } from "./SwitchContext";

const UserEdit: React.FC = () => {
    const { isSwitchOn } = useSwitch();
    const { userId } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [user, setUser] = useState(null);
    const [active, setActive] = useState(false);
    const [sachverstaendiger, setSachverstaendiger] = useState(false);
    const [extern, setExtern] = useState(false);

    useEffect(() => {
        if (userId) {
            fetchUser();
        }
    }, [userId]);

    const fetchUser = async () => {
        try {
            const response = await fetch(`${getAPIurl()}/helper/acl/users/${userId}?populate=role`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            const data = await response.json();
            setUser(data);
            form.setFieldsValue({
                username: data.username,
                email: data.email,
                blocked: data.blocked,
                sachverstaendiger: data.sachverstaendiger,
                extern: data.extern,
                titel: data.titel,
                berufsbezeichnung: data.berufsbezeichnung,
                unternehmen: data.unternehmen,
                strasse: data.strasse,
                hausnummer: data.hausnummer,
                postleitzahl: data.postleitzahl,
                ort: data.ort,
                weblink: data.weblink,
                fax: data.fax,
                telefon: data.telefon,

            });
            setActive(data.blocked);
            setSachverstaendiger(data.sachverstaendiger);
            setExtern(data.extern);
        } catch (error) {
            console.error("Error fetching user:", error);
            message.error("Fehler beim Abrufen des Benutzers.");
        }
    };

    const handleSave = async (values) => {
        try {

            if(values.password == ""){
                values.password = null;
            }
            if(values.password!=null) {
                const passwordError = validatePasswordSecurity(values.password);

                if (passwordError) {
                    message.error(passwordError);
                    return;
                }
            }
            const method = userId ? "PUT" : "POST";
            const url = userId ? `${getAPIurl()}/helper/acl/users/${userId}` : `${getAPIurl()}/users`;
            const putBody = {
                username: values.username,
                email: values.email,
                password: values.password,
                role: 1,
                confirmed: true,
                blocked: active,
                sachverstaendiger: sachverstaendiger,
                extern: extern,
                titel: values.titel,
                berufsbezeichnung: values.berufsbezeichnung,
                unternehmen: values.unternehmen,
                strasse: values.strasse,
                hausnummer: values.hausnummer,
                postleitzahl: values.postleitzahl,
                ort: values.ort,
                weblink: values.weblink,
                fax: values.fax,
                telefon: values.telefon,
            };

            // Remove role from putBody if userId exists
            if (userId) {
                delete putBody.role;
            }

            await fetch(url, {
                method,
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(putBody),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.error && data.error.message === "Email already taken") {
                        console.log(data.error);
                        message.error("Email bereits vergeben");
                    } else if (data.error && data.error.message === "Username already taken") {
                        console.log(data.error);
                        message.error("Es existiert bereits ein Nutzeraccount mit diesem Namen");
                    } else {
                        message.success(`Benutzer erfolgreich ${userId ? 'aktualisiert' : 'erstellt'}.`);
                        navigate('/nutzerverwaltung');
                    }
                    console.log(data);
                })
                .catch(error => console.error('Error:', error));
        } catch (error) {
            console.error(`Error ${userId ? 'updating' : 'creating'} user:`, error);
            message.error(`Fehler beim ${userId ? 'Aktualisieren' : 'Erstellen'} des Benutzers.`);
        }
    };

    return (
        <div className={`lg:w-[84%] w-[90%] ml-auto mr-auto flex justify-center items-center rounded-[30px] ${isSwitchOn ? 'bg-black' : 'bg-white'} userEdit p-[30px]`}>
            <div className={`p-5 w-full max-w-lg`}>
                <h2 className={`font-bold mb-[20px] text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>{userId ? "Benutzer bearbeiten" : "Neuen Benutzer erstellen"}</h2>
                <Form form={form} onFinish={handleSave} initialValues={{ active: false, sachverstaendiger: false, extern: false }}>
                    <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Bitte geben Sie eine Email ein!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="password" label="Passwort" rules={[{ required: !userId, message: 'Bitte geben Sie ein Passwort ein!'  }]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item name="username" label="Name" rules={[{ required: true, message: 'Bitte geben Sie einen Namen ein!' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="titel" label="Titel" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="berufsbezeichnung" label="Berufsbezeichnung" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="unternehmen" label="Unternehmen" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="strasse" label="Straße" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="hausnummer" label="Hausnummer" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="postleitzahl" label="Postleitzahl" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="ort" label="Ort" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="weblink" label="Weblink" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="fax" label="Fax" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="telefon" label="Telefon" >
                        <Input />
                    </Form.Item>
                    <Form.Item name="active" valuePropName="checked">
                        <Switch checked={active} onChange={setActive} /> Deaktiviert
                    </Form.Item>
                    <Form.Item name="sachverstaendiger" valuePropName="checked">
                        <Switch checked={sachverstaendiger} onChange={setSachverstaendiger} /> Sachverständiger
                    </Form.Item>
                    <Form.Item name="extern" valuePropName="checked">
                        <Switch checked={extern} onChange={setExtern} /> Externer Nutzer (Eingeschränkte Zugriffsrechte)
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">Speichern</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default UserEdit;
