import {
    Button,
    Card,
    Col,
    message,
    Row,
    Anchor,
    Form,
    Input,
    InputNumber,
    Modal, Select, DatePicker, AutoComplete
} from "antd";
import React, {useEffect, useRef, useState} from "react";
import { getToken } from "../../helpers";
import {
    addIcon, getAPIurl,
    getInitialValuesFormular, isTextFieldActive,
    LoadingScreen, MyInputNumber,
    optimizedHandleScroll, SaveIcon,
    updateDataContent, useModals
} from "../../helpers/formHelpers";
import { useParams } from "react-router-dom";
import { DeleteOutlined, FileTextOutlined } from "@ant-design/icons";
import 'dayjs/locale/de';
import TextArea from "antd/es/input/TextArea";

import useScreenSize1 from "../../hooks/useScreenSize1";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { useSwitch } from '../../pages/SwitchContext';
// import { HashLink as Link } from "react-router-hash-link";
import { RxCross2 } from "react-icons/rx";
const { Link } = Anchor;
const { Option } = Select;
let dataContainer;
let dataContent;
let enumerationValuesContent;
let updateDataContentValue;
let updateDataContentContainer;
const putBody = {};


const Lage = () => {
    const [vis, setVis] = useState(false);
    const { isDesktopView } = useScreenSize1();
    const { isSwitchOn, toggleSwitch } = useSwitch();
    // navigate = useNavigate();

    useEffect(() => {
        if (isDesktopView) {
            setVis(true);
        } else {
            setVis(false);
        }
    }, [isDesktopView]);
    const handleVisibilityChange = (newVisibility) => {
        setVis(newVisibility);
    };

    return (
        <>
            {/* <Row>
            <Col md={4} lg={4} sm={24} xs={24}>
                
            </Col>
            <Col md={20} lg={20} sm={24} xs={24} className="FormContainer">
                
            </Col>
        </Row> */}
            <div className="w-full">
                <div
                    className={`${!isDesktopView ? "visible  cursor-pointer" : "hidden"
                        } text-white pl-[15px]`}
                    onClick={() => {
                        setVis(!vis);
                    }}
                >
                    <button className="menuBtn">Menu</button>
                    {/* {">"} */}
                </div>
                <div
                    className={`flex flex-row gap-[30px] relative mr-[15px]  mb-[40px] ${!isDesktopView ? "ml-[15px]" : ""
                        }`}
                >
                    {vis ? (
                        <div className="w-[299px] absolute xl:relative z-10 h-full xl:h-auto  ">
                            <MenuContainer
                                vis={vis}
                                onVisibilityChange={handleVisibilityChange} />
                        </div>
                    ) : (
                        ""
                    )}
                    <div className={`w-full ${isSwitchOn ? 'bg-black' : 'bg-white'} rounded-[30px]`}>
                        <FormContainer />
                    </div>
                </div>
            </div>
        </>
    );
};

const MenuContainer = ({ vis, onVisibilityChange }) => {
    const { isSwitchOn, toggleSwitch } = useSwitch();
    window.addEventListener('scroll', optimizedHandleScroll);
    const [isInLower20, setIsInLower20] = useState(false);
    const handleClick = () => {
        const newVisibility = !vis;
        onVisibilityChange(newVisibility);
    };
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            // Check if the device height is less than 600px
            const isDeviceHeightBelow600 = window.innerHeight < 650;

            // Calculate the scroll position percentage
            const scrollPercentage = (scrollPosition / (documentHeight - windowHeight)) * 100;

            // Set the state based on the scroll position and device height
            setIsInLower20(isDeviceHeightBelow600 && scrollPercentage >= 80);
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className={`sidebar rounded-r-[30px] ${isSwitchOn ? 'bg-black' : 'bg-white'}`}>
            <div className={`ml-[197px]`}><RxCross2 onClick={handleClick} className={`xl:hidden ${isSwitchOn ? 'text-white' : 'text-blaxk'} text-[30px] mb-[20px] `} /></div>
            <Anchor affix={false} offsetTop={80} className={`your-component ${isInLower20 ? 'lower-20' : ''}`}>

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#makrolage" title="Makrolage" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#stadtzentrum" title="Stadtzentrum, Geschäfte u. Einrichtungen" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#gebiet" title="Gebiet und Immissionen" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#verkehrsanbindung" title="Verkehrsanbindung" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#wirtschaftsdaten" title="Wirtschaftsdaten" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#beurteilung-lage" title="Beurteilung Lage" />

            </Anchor>

        </div >
    );
};


const FormContainer = () => {
    const [initialValues, setInitialValuesState] = useState([]);
    const [loading, setIsLoading] = useState(false);
    const [saveIcon, setSaveIcon] = useState(false);
    const [saveIcon1, setSaveIcon1] = useState(false);
    const [cardsNC, setCardsNC] = useState([]);
    const [formFormular1] = Form.useForm();
    const inputRefs = useRef({});
    const handleRef = (name, element) => {
        if (element) {
            inputRefs.current[name] = element;
        }
    };
    const { auftragID, gutachtenID } = useParams(); // Get the activeKey from the URL
    const [selectedOption, setSelectedOption] = useState(null);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const { modals, handleOpenTextbausteine, handleCloseTextbausteine, renderModals, selectedTextBausteine } = useModals(); //Drawer for Textbausteine
    const { isSwitchOn, toggleSwitch } = useSwitch();

    //console.log(gutachtenID);
    const handleSelectChange = (value) => {
        setSelectedOption(value);
    };

    const fetchLage = async (jumptoanchor = true ) => {
        setIsLoading(true);

        try {
            const response = await fetch(`${getAPIurl()}/helper/acl/gutachten/${gutachtenID}?populate=deep`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            //console.log(`${API}/vollgutachtens/${gutachtenID}?populate=deep`);
            dataContainer = await response.json();
            dataContent = dataContainer;
            setInitialValuesState(getInitialValuesFormular(dataContent));
            const enumerationValues = await fetch(`${getAPIurl()}/gutachten/enumvalues`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            enumerationValuesContent = await enumerationValues.json();
            if (dataContent.Lage.Makrolage_NextCity) {
                setCardsNC(dataContent.Lage.Makrolage_NextCity);
            }

        } catch (error) {
            console.log(error);
            message.error("Error while fetching profiles!" + error);
        } finally {
            //When everything is loaded jump to anchor (if selected)
            let currentUrl = window.location.href.split("#");
            if(currentUrl[1] && jumptoanchor == true){ // Anchor(=Hashtag) is in URL
                document.querySelector('a[href="#'+currentUrl[1]+'"]').click();
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if(saveIcon){
            fetchLage(false).then(); //No jump to anchor if an update of data triggers reload of data
        } else {
            fetchLage().then();
        }
        const interval = setInterval(() => {
            if(!isTextFieldActive()) { //Not if currently in a textfield
                fetchLage().then();
            }
        }, 30000); // Every 30 Seconds (in case multiple persons are working OR multiple tabs)
        return () => clearInterval(interval);

    }, [updateTrigger]);
    const triggerFocus = (name) => {
        if (inputRefs.current[name]) {
            inputRefs.current[name].focus();
        }
    };
    useEffect(() => {
        if (selectedTextBausteine) {
            formFormular1.setFieldsValue({ [modals[modals.length - 1].field]: selectedTextBausteine });
            triggerFocus(modals[modals.length - 1].field);
        }
    }, [selectedTextBausteine]); // Specify the dependencies to watch for changes

    const addCardNC = () => {

        const newCardNC = {
            MA_nearCity_Name: '',
            MA_Entfernung_km: '',
        };
        document.getElementById("button_new_component").hidden = true; // Button "Neue Komponente" verstecken, da nur eins im Status "undefined" existieren darf
        setCardsNC([...cardsNC, newCardNC]);
    };

    const deleteCardNC = (component, index, id) => {
        Modal.confirm({
            title: 'Komponente löschen',
            content: 'Möchten Sie diese Nächste Stadt wirklich löschen?',
            onOk() {
                deleteCardConfirmedNC(component, index, id);
            },
            onCancel() {
                console.log('Löschung abgebrochen');
            },
        });
    };
    const deleteCardConfirmedNC = (component, index, id) => {
       // console.log(id); //For later delete of upload
        const updatedCardsNC = [...cardsNC];
        updatedCardsNC.splice(index, 1); // Remove the card at the specified index
        setCardsNC(updatedCardsNC);
        updateDataContent(component, index, dataContent, "deleteComponent");
        putBody.data = dataContent;
        updateGutachten(putBody);
    };

    const updateGutachten = (putBody, forceReload = false, forcePageReload = false) => {

        if (forcePageReload) {
            setIsLoading(true);
        } else {
            setSaveIcon(true);
            setSaveIcon1(true)
        }
        fetch(`${getAPIurl()}/helper/acl/gutachten/${gutachtenID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(putBody.data),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to update data.');
                }
                //console.log('putBody:', putBody);
                return response.json();
            })
            .then(() => {
                // New component (undefined item.id) => reload;
                if (forceReload) {
                    if (forcePageReload === true) {
                        // window.location.reload();  // This should be eliminated, just temporarly
                    } else {
                        setUpdateTrigger((prev) => !prev);
                        setIsLoading(false);
                    }
                }
                // Setze das Speicher-Icon nach X Millisekunden zurück
                setTimeout(() => { setSaveIcon(false); }, 300);
                setTimeout(() => {
                    setSaveIcon1(false);
                }, 3000);
            })
            .catch((error) => {
                console.log('putBody:', putBody);
                console.error('Error:', error);
            });
    }
    useEffect(() => formFormular1.resetFields(), [initialValues]);
    return (
        <div className={`${isSwitchOn ? 'bg-black' : 'bg-white'} sm:p-[20px] p-[10px] rounded-[30px]`}>
            {loading && <LoadingScreen />}
            {saveIcon1 && <SaveIcon saveIcon={saveIcon} />}
            {renderModals()}
            <h2 className={`${isSwitchOn ? 'text-white' : 'text-black'} font-bold text-16px  md:text-[28px] mb-[20px] generalText`}>Lage</h2>
            <div>
                {dataContent && initialValues && enumerationValuesContent && ( // Bedingung überprüft, ob dataContent bereits vorhanden ist
                    <Form
                        form={formFormular1}
                        onBlur={(e) => {
                            // Hier wird das onBlur-Ereignis für das gesamte Formular abgefangen
                            if (e.target.type !== "button" && e.target.type !== undefined) { //Not at addCard

                                const updateByIDArray = ["Lage__Makrolage_NextCity"];
                                updateDataContentContainer = dataContent;
                                if (e.target.type === "search") {
                                    updateDataContentValue = selectedOption;
                                    setSelectedOption(null); //Reset (for the case the next select is choosen without value)
                                } else {
                                    updateDataContentValue = e.target.value;
                                }
                                if(e.target.placeholder === "Datum auswählen" && e.target.value == ""){ //In case empty date
                                    updateDataContentValue = null;
                                }
                                if (updateByIDArray.some(value => e.target.id.includes(value))) {
                                    updateDataContent(e.target.id, updateDataContentValue, updateDataContentContainer, "updateByID");
                                } else {
                                    updateDataContent(e.target.id, updateDataContentValue, updateDataContentContainer, "updateByIndex");
                                }
                                putBody.data = dataContent;
                                // New component (undefined item.id) => reload;
                                if (e.target.id.includes("undefined")) {
                                    //  console.log(putBody);
                                    updateGutachten(putBody, true, false);//ForceReload
                                    const elements = document.getElementsByClassName("button_new_component");
                                    for (let i = 0; i < elements.length; i++) {
                                        elements[i].hidden = false;
                                    }
                                } else {
                                    updateGutachten(putBody, false);
                                }

                            }
                        }}
                        initialValues={initialValues}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                    // style={{ maxWidth: 800 }}
                    >
                        <div id="makrolage" className="scrollable-section">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Makrolage</h3>
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Bundesland" name="Lage__MA_BundeslandV">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea "}
                                        options={enumerationValuesContent?.sub_com_MA_Bundesland?.map(
                                            (option, index) => ({ value: option, key: index })
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Bundesland"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                                <Form.Item label="Landeshauptstadt" name='Lage__MA_Landeshauptstadt'>
                                    <Input className="inputsAll" />
                                </Form.Item>
                                <Form.Item label="Landkreis" name='Lage__MA_Landkreis'>
                                    <Input className="inputsAll" />
                                </Form.Item>
                                <Form.Item label="Kreisstadt" name='Lage__MA_Kreisstadt'>
                                    <Input className="inputsAll" />
                                </Form.Item>
                                <Form.Item label="Ort" name='Lage__MA_Ort'>
                                    <Input className="inputsAll" />
                                </Form.Item>
                                <Form.Item label="Ortsteil" name='Lage__MA_Ortsteil'>
                                    <Input className="inputsAll" />
                                </Form.Item>
                                <Form.Item label="Einwohnerzahl Ort" name='Lage__MA_Einwohner_Ort'>
                                    <MyInputNumber className="inputsAll" />
                                </Form.Item>
                                <Form.Item label="Einwohnerzahl Ortsteil" name='Lage__MA_Einwohner_Ortsteil'>
                                    <Input className="inputsAll" />
                                </Form.Item>
                                <Form.Item label={`Stichdatum Einwohnerzahl`} >
                                    <Form.Item name={`Lage__MA_Einwohner_Stichdatum`} noStyle>
                                        <DatePicker format={"DD.MM.YYYY"} className="inputsAll" />
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label="Beschreibung der Makrolage" name='Lage__MA_Beschreibung_Makrolage'>
                                    <TextArea autoSize={true} className="textInput" />
                                </Form.Item>
                            </div>
                            <div className="scrollable-section border-t border-gray-300">
                                <div
                                    className="flex justify-between items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                    <h4 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Nächste
                                        Städte</h4>

                                </div>

                                {cardsNC.map((entry, index) => (
                                    <Card className={`pl-[3%] md:pl-[10%] ${isSwitchOn ? 'bgdark' : 'bgwhite'}`}
                                          size="small" key={index}>
                                        <div
                                            className={`flex justify-center  sacherCenterText ${isSwitchOn ? 'maxColor' : ''}`}>
                                            <Form.Item label={`Name der Stadt:`}
                                                       name={`Lage__Makrolage_NextCity__${entry.id}__MA_nearCity_Name`}>
                                                <Input className="inputsAll"/>
                                            </Form.Item>
                                            <div className="ml-[10px] gap-[10px] flex">
                                                <div style={{float: 'right', cursor: 'pointer'}}
                                                     onClick={() => deleteCardNC("Lage.Makrolage_NextCity", index, entry.id)}>
                                                    <RiDeleteBin5Fill
                                                        className="text-[#FF2E3B] w-[18px] h-[20px] mt-[40px] sm:mt-0"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                            <Form.Item label={`Entfernung in KM: `}
                                                       name={`Lage__Makrolage_NextCity__${entry.id}__MA_Entfernung_km`}>
                                                <MyInputNumber className="inputsAll"/>
                                            </Form.Item>
                                        </div>
                                    </Card>
                                ))}
                                <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Button
                                        className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'} text-[10px] m-[20px] md:text-[14px] button_new_component rounded-[30px] border-none`}
                                        onClick={addCardNC} id={"button_new_component"}>Stadt hinzufügen</Button>
                                </div>
                            </div>
                        </div>
                        <div id="stadtzentrum"
                             className="scrollable-section border-t border-gray-300 pt-[20px] pb-[20px]">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Stadtzentrum,
                                Geschäfte u. Einrichtungen</h3>
                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Stadtzentrum" name='Lage__Stadtzentrum_TB' >
                                    <TextArea className="textInput" autoSize={true}  ref={(element) => handleRef('Lage__Stadtzentrum_TB', element)}/>
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Lage__Stadtzentrum_TB', 'Stadtzentrum', 'Stadtzentrum')} style={{ float: 'right' }} ><FileTextOutlined /></Button>

                            </div>

                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Geschäfte" name='Lage__Geschaefte_TB'>
                                    <TextArea autoSize={true} className="textInput"  ref={(element) => handleRef('Lage__Geschaefte_TB', element)} />
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Lage__Geschaefte_TB', 'Geschäfte', 'Geschäfte/Einrichtungen')} style={{ float: 'right' }} ><FileTextOutlined /></Button>

                            </div>

                        </div>
                        <div id="gebiet" className="scrollable-section border-t border-gray-300 pt-[20px] pb-[20px]">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Gebiet und Immissionen</h3>
                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Freizeit/Naherholung" name='Lage__Freizeit_TB'>
                                    <TextArea autoSize={true} className="textInput"  ref={(element) => handleRef('Lage__Freizeit_TB', element)} />
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Lage__Freizeit_TB', 'Freizeit/Naherholung', 'Freizeit/Naherholung')} style={{ float: 'right' }} ><FileTextOutlined /></Button>

                            </div>

                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Art des Gebiets" name='Lage__ArtDesGebiets_TB'>
                                    <TextArea autoSize={true} className="textInput"  ref={(element) => handleRef('Lage__ArtDesGebiets_TB', element)} />
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Lage__ArtDesGebiets_TB', 'Art des Gebiets', 'Art des Gebiets')} style={{ float: 'right' }} ><FileTextOutlined /></Button>

                            </div>

                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Immissionen/Beeinträchtigungen" name='Lage__Immissionen_TB'>
                                    <TextArea autoSize={true} className="textInput"  ref={(element) => handleRef('Lage__Immissionen_TB', element)} />
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Lage__Immissionen_TB', 'Immissionen/Beeinträchtigungen', 'Immissionen/Beeinträchtigungen')} style={{ float: 'right' }} ><FileTextOutlined /></Button>

                            </div>

                        </div>
                        <div id="verkehrsanbindung" className="scrollable-section border-t border-gray-300 pt-[20px] pb-[20px]">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Verkehrsanbindung</h3>
                            <div className="allgemeineClass">
                                <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="ÖPNV" name='Lage__OePNV_TB'>
                                        <TextArea autoSize={true} className="textInput"  ref={(element) => handleRef('Lage__OePNV_TB', element)} />
                                    </Form.Item>
                                    <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Lage__OePNV_TB', 'ÖPNV', 'ÖPNV')} style={{ float: 'right' }} ><FileTextOutlined /></Button>
                                </div>

                                <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Nächstgelegene Straße" name='Lage__NaechstegelegeneStrassen'>
                                        <TextArea autoSize={true} className="textInput"  ref={(element) => handleRef('Lage__NaechstegelegeneStrassen', element)} />
                                    </Form.Item>
                                    <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Lage__NaechstegelegeneStrassen', 'Straßen', 'Strassen')} style={{ float: 'right' }} ><FileTextOutlined /></Button>
                                </div>

                                <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Bundesstraßen" name='Lage__Bundesstrassen'>
                                        <TextArea autoSize={true} className="textInput"  ref={(element) => handleRef('Lage__Bundesstrassen', element)} />
                                    </Form.Item>

                                    <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Lage__Bundesstrassen', 'Bundesstraßen', 'Bundesstrassen')} style={{ float: 'right' }} ><FileTextOutlined /></Button>
                                </div>

                                <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Autobahn und Zufahrt" name='Lage__AutobahnUndZufahrt_TB'>
                                        <TextArea autoSize={true} className="textInput"  ref={(element) => handleRef('Lage__AutobahnUndZufahrt_TB', element)} />
                                    </Form.Item>

                                    <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Lage__AutobahnUndZufahrt_TB', 'Autobahn', 'Autobahn')} style={{ float: 'right' }} ><FileTextOutlined /></Button>
                                </div>

                                <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Bahnhof" name='Lage__Bahnhof_TB'>
                                        <TextArea autoSize={true} className="textInput"  ref={(element) => handleRef('Lage__Bahnhof_TB', element)} />
                                    </Form.Item>

                                    <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Lage__Bahnhof_TB', 'Bahnhof', 'Bahnhof')} style={{ float: 'right' }} ><FileTextOutlined /></Button>
                                </div>

                                <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label="Flughafen" name='Lage__Flughafen_TB'>
                                        <TextArea autoSize={true} className="textInput"  ref={(element) => handleRef('Lage__Flughafen_TB', element)} />
                                    </Form.Item>

                                    <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Lage__Flughafen_TB', 'Flughafen', 'Flughafen')} style={{ float: 'right' }} ><FileTextOutlined /></Button>
                                </div>

                            </div>
                        </div>
                        <div id="wirtschaftsdaten" className="scrollable-section border-t border-gray-300 pt-[20px] pb-[20px]">
                            <h4 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Wirtschaftsdaten</h4>
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Informationen" name='Lage__Wirtschaftsdaten__WD_Informationen'>
                                    <TextArea className="textInput" />
                                </Form.Item>
                                <Form.Item label="Kaufkraft" name='Lage__Wirtschaftsdaten__WD_Kaufkraft'>
                                    <Input className="inputsAll" />
                                </Form.Item>
                                <Form.Item label="Arbeitslosenquote" name='Lage__Wirtschaftsdaten__WD_Arbeitslosenquote'>
                                    <Input className="inputsAll" />
                                </Form.Item>
                                <Form.Item label="Kaufkraft Ortsteil" name='Lage__Wirtschaftsdaten__WD_Kaufkraft_Ortsteil'>
                                    <Input className="inputsAll" />
                                </Form.Item>
                                <Form.Item label="Arbeitslosenquote Ortsteil" name='Lage__Wirtschaftsdaten__WD_Arbeitslosigkeit_Ortsteil'>
                                    <Input className="inputsAll" />
                                </Form.Item>
                            </div>
                        </div>
                        <div id="beurteilung-lage" className="scrollable-section border-t border-gray-300 pt-[20px] pb-[20px]">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Beurteilung der Lage</h3>
                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Beurteilung der Lage" name='Lage__Beurteilung_Lage'>
                                    <TextArea autoSize={true} className="textInput"  ref={(element) => handleRef('Lage__Beurteilung_Lage', element)} />
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon" onClick={() => handleOpenTextbausteine('Lage__Beurteilung_Lage', 'Beurteilung der Lage', 'Lage')} style={{ float: 'right' }} ><FileTextOutlined /></Button>

                            </div>
                        </div>

                    </Form>
                )}
            </div>
        </div>
    );
};

export default Lage;
