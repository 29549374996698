import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, message, Space, Table, Modal } from "antd";
import { getToken } from "../helpers";
import { useAuthContext } from "../context/AuthContext";
import Sidebar from "../components/sidebar";
import useScreenSize from "../hooks/useScreenSize";
import { useSwitch } from "../pages/SwitchContext";
import { Link } from "react-router-dom";
import axios from "axios";
import {MdDelete, MdEdit} from "react-icons/md";
import {getAPIurl, handleCreateKontakt} from "../helpers/formHelpers";

const { confirm } = Modal;

let user;
let dataContainer;
let dataContent;

let navigate;
let sideOptions = [
    { value: "Auftraggeber" },
    { value: "Eigentümer" },
    { value: "Sachverständiger" },
    { value: "Gutachterausschuss" },
    { value: "Grundbuch" },
    { value: "Geoinformation" },
    { value: "Bauordnung/Baulasten" },
    { value: "Denkmalschutz" },
    { value: "Weitere Behörden" },
    { value: "Sonstige" },
];

/* Replaced by archive function

const handleDelete = (id) => {
    confirm({
        title: "Kontakt löschen",
        content: "Möchten Sie diesen Kontakt wirklich löschen?",
        onOk() {
            handleDeleteRequest(id).then((r) => { });
        },
        onCancel() {
            console.log("Löschung abgebrochen");
        },
    });
};

const handleDeleteRequest = async (id) => {
    try {
        // Sende einen DELETE-Request an deine Strapi-API, um den neuen Eintrag zu erstellen
        const response = await fetch(
            `${getAPIurl()}/helper/acl?id=${id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                },
            }
        );

        if (response.ok) {
            axios
                .delete(`${getAPIurl()}/helper/acl/upload/files/${id}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                })
                .then((res) => {
                    console.log(res, "res");
                });
            // window.location.reload();
        } else {
            // Behandle den Fall, wenn der POST-Request fehlschlägt
            console.error("Fehler beim Erstellen des Eintrags.");
        }
    } catch (error) {
        // Behandle andere Fehler, die auftreten könnten
        console.error("Fehler beim Erstellen des Eintrags:", error);
    }
};*/

const KontakteUebersicht = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [vis, setVis] = useState(false);
    const { isDesktopView } = useScreenSize();
    user = useAuthContext();
    navigate = useNavigate();
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const [search, setSearch] = useState("");
    const [tableData, setTableData] = useState([])
    const [selected, setSelected] = useState("")


    const fetchKontakte = async () => {
        setIsLoading(true);

        try {
            const response = await fetch(`${getAPIurl()}/helper/acl/kontakte?filters[archived][$eq]=0`, {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            dataContainer = await response.json();
            setTableData(dataContainer.map((item, index) => {
                return {
                    key: index,
                    id: item.id,
                    kontaktname: item.Kontaktname,
                    telefon: item.Telefon,
                    mail: item.Mail,
                    unternehmen: item.Unternehmen,
                    kontakttypV: item.KontakttypV,
                };
            }))
        } catch (error) {
            console.log(error);
            message.error("Error while fetching profiles!");
        } finally {
            setIsLoading(false);
        }
    };

    const deleteKontakt = (id) => {
        Modal.confirm({
            title: "Kontakt löschen",
            content: "Möchten Sie diesen Kontakt wirklich löschen?",
            onOk() {
                deleteKontaktConfirm(id).then();
            },
            onCancel() {
                console.log("Erstellen abgebrochen");
            },
        });
    }

    const deleteKontaktConfirm = async (id) => {
        try {
            const response = await fetch(`${getAPIurl()}/helper/acl/kontakte?id=${id}&populate=deep`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            dataContent = await response.json();
            if (dataContent) {
                dataContent.archived = 1;
                // Sende einen PUT-Request an deine Strapi-API, um den neuen Eintrag zu bearbeiten
                const responseUpdate = await fetch(`${getAPIurl()}/helper/acl/kontakte?id=${id}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getToken()}`,
                    },
                    body: JSON.stringify(dataContent),
                });
                if (responseUpdate.ok) {
                    fetchKontakte().then(); // Refresh
                } else {
                    // Behandle den Fall, wenn der POST-Request fehlschlägt
                    console.error("Fehler beim Löschen des Eintrags.");
                }
            }

        } catch (error) {
            // Behandle andere Fehler, die auftreten könnten
            console.error("Fehler beim Löschen des Eintrags:", error);
        }
    }

    useEffect(() => {
        if (search !== "" || selected !== "") {
            Search();

        } else {
            fetchKontakte().then();
        }
    }, [search, selected]);
    useEffect(() => {
        if (isDesktopView) {
            setVis(true);
        } else {
            setVis(false);
        }
    }, [isDesktopView]);
    const Search = () => {
        let url
        if (selected == "Weitere Behörden") {
            url = `${getAPIurl()}/helper/acl/kontakte?sort[0]=id:desc&filters[$or][0][[KontakttypV][$eq]=Stadt-/Gemeindeverwaltung&filters[$or][1][[KontakttypV][$eq]=Kreisverwaltung&filters[$or][2][[KontakttypV][$eq]=Planung&filters[$or][3][[KontakttypV][$eq]=Bauen&filters[$or][4][[KontakttypV][$eq]=Altlasten&filters[$or][5][[KontakttypV][$eq]=Bauakten&filters[$and][6][archived][$eq]=0`
        } else if (selected == "Auftraggeber") {
            url = `${getAPIurl()}/helper/acl/kontakte?sort[0]=id:desc&filters[$or][0][[KontakttypV][$eq]=Auftraggeber&filters[$or][1][[KontakttypV][$eq]=Auftraggeber%20%26%20Eigentümer`
        } else if (selected == "Eigentümer") {
            url = `${getAPIurl()}/helper/acl/kontakte?sort[0]=id:desc&filters[$or][0][[KontakttypV][$eq]=Eigentümer&filters[$or][1][[KontakttypV][$eq]=Auftraggeber%20%26%20Eigentümer`
        } else if (selected == "Sonstige") {
            url = `${getAPIurl()}/helper/acl/kontakte?sort[0]=id:desc&filters[$or][0][[KontakttypV][$eq]=Mieter&filters[$or][1][[KontakttypV][$eq]=Hausverwalter&filters[$or][2][[KontakttypV][$eq]=Makler&filters[$or][3][[KontakttypV][$eq]=Notar&filters[$or][4][[KontakttypV][$eq]=Rechtsanwalt&filters[$or][5][[KontakttypV][$eq]=Steuerberater&filters[$or][6][[KontakttypV][$eq]=Wirtschaftsprüfer&filters[$and][7][archived][$eq]=0`
        } else if (search !== "") {
            url = `${getAPIurl()}/helper/acl/kontakte?pagination[page]=1&filters[$or][0][KontakttypV][$contains]=${search}&filters[$or][1][Unternehmen][$contains]=${search}&filters[$or][2][Vornamen][$contains]=${search}&filters[$or][3][Nachname][$contains]=${search}&filters[$or][4][Kontaktname][$contains]=${search}&filters[$and][5][archived][$eq]=0`
        } else {
            url = `${getAPIurl()}/helper/acl/kontakte?sort[0]=id:desc&filters[$and][0][KontakttypV][$eq]=${selected ? selected : search}&filters[$and][1][archived][$eq]=0`

        }

        axios
            .get(url,

                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            .then(async (response) => {
                let tableDataa = await response.data.map((item, index) => {
                    return {
                        key: index,
                        id: item.id,
                        kontaktname: item.Kontaktname,
                        telefon: item.Telefon,
                        mail: item.Mail,
                        unternehmen: item.Unternehmen,
                        kontakttypV: item.KontakttypV,
                    };
                });
               // console.log(tableDataa);
                setTableData(tableDataa)
            })
            .catch((err) => {
                console.log(err);
            });
    };



    const columns = [
        {
            title: "Kontaktname",
            dataIndex: "kontaktname",
            key: "kontaktname",
        },
        {
            title: "Kontakttyp",
            dataIndex: "kontakttypV",
            key: "kontakttypV",
        },
        {
            title: "Unternehmen",
            dataIndex: "unternehmen",
            key: "unternehmen",
        },
        {
            title: "Mail",
            dataIndex: "mail",
            key: "mail",
        },
        {
            title: "Telefon",
            dataIndex: "telefon",
            key: "telefon",
        },
        {
            title: "Aktionen",
            key: "actions",
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/kontakte/${record.id}`}>
                        <MdEdit className={`text-[22px] ${isSwitchOn ? 'textwhite' : 'text-black'} mb-[5px] mr-[-10px] text-[#FF2E3B]`} />
                    </Link>
                    <Button onClick={() => deleteKontakt(record.id)} className={`border-0`}>
                        <MdDelete className={`text-[22px] ${isSwitchOn ? 'textwhite' : 'text-black'} mb-[5px] mr-[-10px] text-[#FF2E3B]`} />
                    </Button>
                </Space>
            ),
        },
    ];





    const handleVisibilityChange = (newVisibility) => {
        setVis(newVisibility);
    };

    return (
        <div class="w-full">

            <div
                className={`flex flex-row gap-[30px]  mr-[40px]  mb-[40px] ${!isDesktopView ? "ml-[40px]" : ""
                    }`}
            >
                {vis ? (
                    <Sidebar
                        sideOptions={sideOptions}
                        handleCreate={() => handleCreateKontakt(navigate)}
                        head="Kontakt erstellen"
                        pageTittle="Kontakte"
                        selected={selected}
                        setSelected={setSelected}
                        vis={vis}
                        onVisibilityChange={handleVisibilityChange}
                    />
                ) : (
                    ""
                )}
                <div
                    style={{ overflowX: "hidden" }}
                    className="rounded-[30px]   w-full"
                >

                    <div
                        className={` relative w-[80%] sm:w-[489px] h-[54px]   flex justify-between rounded-[30px] shadow px-[20px] items-center mb-[17px]  ${isSwitchOn ? "bg-black" : "bg-white"
                            }`}
                    >
                        <img src={require("../assets/search.png")} className=" " alt="" />
                        <input
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                                setSelected("")
                            }}
                            type="text"
                            className={` w-[100%] h-full focus:border-none outline-none shadow-none rounded pl-[10px] ${isSwitchOn ? "text-white" : "text-neutral-700"
                                } ${isSwitchOn ? "bgdark" : "bgwhite"}`}
                            placeholder="Suchen..."
                        />
                    </div>
                    <div
                        className={`${!isDesktopView ? "visible  cursor-pointer" : "hidden"
                            } text-white `}
                        onClick={() => {
                            setVis(!vis);
                        }}
                    >
                        <button className="menuBtn">Menu</button>
                    </div>
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        responsive
                        pagination={{
                            pageSizeOptions: [20, 50, 100, 150],
                            defaultPageSize: 20,
                            locale: {items_per_page: "Einträge / Seite"}
                        }}
                        style={{
                            background: "white",
                            overflowX: "auto",
                            borderRadius: "30px",
                            padding: "20px",
                        }}
                        className={`${isSwitchOn ? "bgdark" : "bgwhite"}  ${isSwitchOn ? "tableComtainer" : "tableComtainerBlack"
                            }`}
                    />
                </div>
            </div>
        </div>
    );
};

export default KontakteUebersicht;
