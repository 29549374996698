import React, { useEffect, useState } from "react";
import useScreenSize from "../../hooks/useScreenSize";
import { useSwitch } from '../../pages/SwitchContext';
import SwitchButton from "../SwitchButton/SwitchButton";
import { RxCross2 } from "react-icons/rx";
import {Link} from "react-router-dom";
import {useAuthContext} from "../../context/AuthContext";

const Sidebar = ({ vis, onVisibilityChange, pageTittle, sideOptions, handleCreate, head, selected, setSelected }) => {
    const { isDesktopView } = useScreenSize();
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const [select, setSelect] = useState("")
    const { user, setUser } = useAuthContext();
    useEffect(() => {
        setSelect(selected)
    }, [selected])


    const handleClick = () => {
        const newVisibility = !vis;
        onVisibilityChange(newVisibility);
    };


    return (
        <div className={`min-w-[244px] mt-[122px] lg:mt-0 lg:pb-0 lg:min-h-full pb-[50px]  ${isSwitchOn ? 'bg-black' : 'bg-white'} rounded-tr-[30px] rounded-br-[30px] shadow flex  flex-col ${!isDesktopView ? "absolute" : "md:relative"} z-[500]`}>
            <div className={`ml-[197px]`}><RxCross2 onClick={handleClick} className={`lg:hidden ${isSwitchOn ? 'text-white' : 'text-black'} text-[30px] mt-[20px]`} /></div>
            <div className="flex flex-col pl-[30px]">
                <div className="text-neutral-700 text-2xl font-bold mt-[35px]">
                    {pageTittle}
                </div>
                {user && !user.extern && (
                <div onClick={handleCreate} className={`w-[190px] h-12 px-5 py-[15px] ${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'} rounded-[31px] shadow justify-center items-center gap-2.5 inline-flex mt-[41px] mb-[37px] cursor-pointer`}>
                    <div className={` text-[15px] font-semibold`}>
                        {head}
                    </div>
                </div>)}
            </div>
            <div className="pl-[30px] text-neutral-700 text-sm font-normal w-[157px] h-[40px]">
                Filtern nach:   {selected !== "" && <div onClick={() => setSelected("")}  className={`w-[20px] h-4 px-5 py-[10px] ${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'} rounded-[31px] shadow justify-center items-center gap-2.5 inline-flex ml-[0px] mb-[0px] cursor-pointer`}>
                <div className={` text-[15px] font-semibold`}>
                    X
                </div>
            </div>}
            </div>
            <div className="pb-[50px]">
                {sideOptions.map((dd, ind) => {
                    return (
                        <div key={ind} onClick={() => { setSelected(dd?.value) }} className={`w-full h-[40px] pl-[27px]  flex  items-center cursor-pointer ${dd?.value === selected ? "act text-[11px] selectedItem text-[#FF2E3B] font-semibold text-lg" : ' text-zinc-500 text-base font-normal'} `}>
                            {/* {dd?.value === selected ? <div className="w-1 h-[40px] bg-red-500" /> : ''} */}
                            {dd.icon && (
                                <span className="flex items-center mr-2">
                                    <img src={dd.icon} alt={dd.value} className="w-6 h-6" />
                                </span>
                            )}

                            <span className={dd?.value === selected ? " flex pr-[5px] text-base " : " text-[13px] flex"}>
                                {dd?.value === selected ? (
                                        <span className="flex items-base text-base font-normal" >{dd?.value}</span>
                                ) : (
                                    <span>{dd?.value}</span>
                                )}
                            </span>
                        </div>
                    );
                })}

            </div>

        </div>
    );
};

export default Sidebar;
