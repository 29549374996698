import {
    Button,
    Card,
    Col,
    message,
    Row,
    Anchor,
    Form,
    Input,
    InputNumber,
    Upload,
    DatePicker,
    Modal, Select, Switch
} from "antd";
import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../helpers";
import {
    downloadRenderFile, getAPIurl,
    getInitialValuesFormular, isTextFieldActive, LoadingScreen, MyInputNumber,
    openS3File, optimizedHandleScroll, SaveIcon,
    setInitialUploadForms,
    updateDataContent,
    uploadFile, useModals
} from "../../helpers/formHelpers";
import { useParams } from "react-router-dom";
import { DeleteOutlined, DownloadOutlined, ExportOutlined, FileTextOutlined, UploadOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";

import { RiDeleteBin5Fill } from "react-icons/ri";
import TextArea from "antd/es/input/TextArea";
import useScreenSize1 from "../../hooks/useScreenSize1";
import { useSwitch } from '../../pages/SwitchContext';
import { RxCross2 } from "react-icons/rx";

const { Link } = Anchor;
const { Option } = Select;

let dataContainer;
let dataContent;

let enumerationValuesContent;
let updateDataContentValue;
let updateDataContentContainer;

let currentFileChanged;
let currentFileChangedID;
let currentFileChangedComponent;

const putBody = {};


const AllgemeineAngaben = () => {
    const [vis, setVis] = useState(false);
    const { isDesktopView } = useScreenSize1();
    const { isSwitchOn, toggleSwitch } = useSwitch();
    // navigate = useNavigate();

    useEffect(() => {
        if (isDesktopView) {
            setVis(true);
        } else {
            setVis(false);
        }
    }, [isDesktopView]);

    const handleVisibilityChange = (newVisibility) => {
        setVis(newVisibility);
    };

    return (
        // <Row>
        //     <Col md={4} lg={4} sm={24} xs={24}>
        //         
        //     </Col>
        //     <Col md={20} lg={20} sm={24} xs={24} className="FormContainer">
        //         
        //     </Col>
        // </Row>
        <>
            <div className="w-full">
                <div
                    className={`${!isDesktopView ? "visible  cursor-pointer" : "hidden"
                        } text-white pl-[15px]`}
                    onClick={() => {
                        setVis(!vis);
                    }}
                >
                    <button className="menuBtn">Menu</button>
                    {/* {">"}
                    {">"} */}
                </div>
                <div
                    className={`flex flex-row gap-[30px] relative mr-[15px]  mb-[40px] ${!isDesktopView ? "ml-[15px]" : ""
                        }`}
                >
                    {vis ? (
                        <div className="w-[299px] absolute xl:relative z-10 lowerHeight1 xl:h-auto  ">
                            <MenuContainer
                                vis={vis}
                                onVisibilityChange={handleVisibilityChange}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    <div className={`w-full ${isSwitchOn ? 'bg-black' : 'bg-white'} rounded-[30px]`}>
                        <FormContainer />
                    </div>
                </div>
            </div>
        </>
    );
};

const MenuContainer = ({ vis, onVisibilityChange }) => {
    const { isSwitchOn, toggleSwitch } = useSwitch();
    window.addEventListener('scroll', optimizedHandleScroll);
    const [isInLower20, setIsInLower20] = useState(false);
    const handleClick = () => {
        const newVisibility = !vis;
        onVisibilityChange(newVisibility);
    };
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;

            // Check if the device height is less than 600px
            const isDeviceHeightBelow600 = window.innerHeight < 600;

            // Calculate the scroll position percentage
            const scrollPercentage = (scrollPosition / (documentHeight - windowHeight)) * 100;

            // Set the state based on the scroll position and device height
            setIsInLower20(isDeviceHeightBelow600 && scrollPercentage >= 80);
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className={`sidebar rounded-r-[30px] ${isSwitchOn ? 'bg-black' : 'bg-white'}`}>
            <div className={`ml-[197px]`}><RxCross2 onClick={handleClick} className={`xl:hidden ${isSwitchOn ? 'text-white' : 'text-black'} text-[30px] mb-[20px] `} /></div>
            <Anchor affix={false} offsetTop={80} className={`your-component ${isInLower20 ? 'lower-20' : ''}`}>
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#organisatorisches" title="Organistatorisches" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#grundbuch-kataster" title="Grundbuch und Kataster" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#sondereigentum" title="Sondereigentum" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#ortstermin" title="Ortstermin" />

                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#unterlagen" title="Unterlagen" />
                <Link className={`${isSwitchOn ? 'textwhite' : 'textdark'}`} href="#hinweise" title="Hinweise" />


            </Anchor>
        </div>
    );
};


const FormContainer = () => {
    const [cards, setCards] = useState([]);
    const [loading, setIsLoading] = useState(false);
    const [saveIcon, setSaveIcon] = useState(false);
    const [saveIcon1, setSaveIcon1] = useState(false);
    const [formFormular1] = Form.useForm();
    const inputRefs = useRef({});
    const [initialValues, setInitialValuesState] = useState([]);
    const handleRef = (name, element) => {
        if (element) {
            inputRefs.current[name] = element;
        }
    };
    const { auftragID, gutachtenID, tab } = useParams(); // Get the activeKey from the URL
    const [selectedOption, setSelectedOption] = useState(null);
    const { isSwitchOn, toggleSwitch } = useSwitch();

    const [uploadForms, setUploadForms] = useState([]);
    const [updateTrigger, setUpdateTrigger] = useState(false);
    const updatedUploadForms = [...uploadForms];
    const { modals, handleOpenTextbausteine, handleCloseTextbausteine, renderModals, selectedTextBausteine } = useModals(); //Drawer for Textbausteine



    const handleSelectChange = (value) => {
        setSelectedOption(value);
    };

    const fetchAllgemeineAngaben = async (jumptoanchor = true) => {
        setIsLoading(true);

        try {
            const response = await fetch(`${getAPIurl()}/helper/acl/gutachten/${gutachtenID}?populate=deep`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });

            dataContainer = await response.json();
            dataContent = dataContainer;
            setInitialValuesState(getInitialValuesFormular(dataContent));
            const enumerationValues = await fetch(`${getAPIurl()}/gutachten/enumvalues`, { // &filters[role][$eq]=${userRole}
                headers: {
                    // set the auth token to the user's jwt
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            enumerationValuesContent = await enumerationValues.json();
            setCards(dataContent.AllgAng);
            setUploadForms(setInitialUploadForms(updatedUploadForms, dataContent.AllgAng.Unterlagen, "Unterlagen", false));
        } catch (error) {
            message.error("Error while fetching profiles!" + error);
        } finally {
            //When everything is loaded jump to anchor (if selected)
            let currentUrl = window.location.href.split("#");
            if(currentUrl[1] && jumptoanchor == true){ // Anchor(=Hashtag) is in URL
                document.querySelector('a[href="#'+currentUrl[1]+'"]').click();
            }
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if(saveIcon){
            fetchAllgemeineAngaben(false).then(); //No jump to anchor if an update of data triggers reload of data
        } else {
            fetchAllgemeineAngaben().then();
        }
        const interval = setInterval(() => {
            if(!isTextFieldActive()) { //Not if currently in a textfield
                fetchAllgemeineAngaben().then();
            }
        }, 30000); // Every 30 Seconds (in case multiple persons are working OR multiple tabs)
        return () => clearInterval(interval);

    }, [updateTrigger]);
    const triggerFocus = (name) => {
        if (inputRefs.current[name]) {
            inputRefs.current[name].focus();
        }
    };
    useEffect(() => {
        if (selectedTextBausteine) {
            formFormular1.setFieldsValue({ [modals[modals.length - 1].field]: selectedTextBausteine });
            triggerFocus(modals[modals.length - 1].field);
        }
    }, [selectedTextBausteine]); // Specify the dependencies to watch for changes

    /**
     * Generates a new card and adds it to the specified component.
     *
     * @param {string} component - The component to add the card to.
                            * @param {boolean} [uploadForm=false] - Indicates whether an upload form should be created for the new card.
                            */
    const addCard = (component, uploadForm = false) => {
        document.getElementById(`button_new_component_${component}`).hidden = true;

        if (uploadForm) {
            const updatedUploadFormsAdd = uploadForms;
            updatedUploadFormsAdd[component][undefined] = {
                file: "",
                visible: true,
            };
            setUploadForms(updatedUploadFormsAdd);
        }

        setCards({
            ...cards,
            [component]: [...cards[component], {}],
        });
    };
    /**
     * Deletes a card from the specified component at the given index with the provided ID.
     *
     * @param {string} component - The component from which the card will be deleted.
                            * @param {number} index - The index of the card in the component.
                            * @param {string} id - The ID of the card to be deleted.
                            */
    const deleteCard = (component, index, id) => {
        Modal.confirm({
            title: 'Komponente löschen',
            content: 'Möchten Sie diesen Eintrag wirklich löschen?',
            onOk() {
                deleteCardConfirmed(component, index, id);
            },
            onCancel() {
                console.log('Löschung abgebrochen');
            },
        });
    };

    /**
     * Deletes a card from the specified component.
     *
     * @param {string} component - The name of the component.
                            * @param {number} index - The index of the card to be deleted.
                            * @param {string} id - The ID of the card to be deleted.
                            */
    const deleteCardConfirmed = (component, index, id) => {
        const currentComponent = dataContent.AllgAng[component][index];
        for (const key in currentComponent) {
            if (currentComponent.hasOwnProperty(key)) {
                if(typeof currentComponent[key] == "object" && currentComponent[key] != null  && currentComponent[key].id){
                    handleRemoveAttachmentConfirmed("", currentComponent[key].id).then();
                }
            }
        }
        const updatedCardsComponent = [...cards[component]];
        updatedCardsComponent.splice(index, 1); // Remove the card at the specified index
        cards[component] = updatedCardsComponent;
        setCards(cards);
        updateDataContent(component, index, dataContent, "deleteComponent");
        putBody.data = dataContent;
        updateGutachten(putBody, false);
    };
    const updateGutachten = (putBody, forceReload = false, forcePageReload = false) => {
        if (forcePageReload) {
            setIsLoading(true);
        } else {
            setSaveIcon(true);
            setSaveIcon1(true)
        }

        fetch(`${getAPIurl()}/helper/acl/gutachten/${gutachtenID}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify(putBody.data),
        })
            .then((response) => {

                if (!response.ok) {
                    throw new Error('Failed to update data.');
                }
                //console.log('putBody:', putBody);
                return response.json();
            })
            .then((responseData) => {
                // New component (undefined item.id) => reload;
                if (forceReload) {
                    if (forcePageReload == true) {
                        // window.location.reload();  // This should be eliminated, just temporarly
                    } else {
                        setUpdateTrigger((prev) => !prev);
                        setIsLoading(false);
                    }
                }
                // Setze das Speicher-Icon nach X Millisekunden zurück
                setTimeout(() => { setSaveIcon(false); }, 300);
                setTimeout(() => {
                    setSaveIcon1(false);
                }, 3000);
            })
            .catch((error) => {
                console.log('putBody:', putBody);
                console.error('Error:', error);
            });
    }

    const customUploadFile = async (file) => {

        try {
            if (file) {
                const responseUF = await uploadFile(file, false, auftragID);

                // Check if the response is an array
                updateDataContentValue = {}

                if (Array.isArray(responseUF)) {
                    // Use forEach to iterate through the array (normally 1, because multiple files are not allowed)
                    responseUF.forEach((item, index) => {
                        updateDataContentValue["url"] = item.hash + item.ext;
                        updateDataContentValue["id"] = item.id;
                        updateDataContentValue["name"] = item.name;
                    });
                }

                // Save changes in database
                updateDataContentContainer = dataContent;
                updateDataContent(currentFileChanged, updateDataContentValue, updateDataContentContainer, "updateByID");
                putBody.data = dataContent;
                updateGutachten(putBody, true, false);
                const elements = document.getElementsByClassName("button_new_component");
                for (let i = 0; i < elements.length; i++) {
                    elements[i].hidden = false;
                }

            }
        } catch (error) {
            console.error(error);
        }
    };

    /* function setInitialUploadForms(files,name){
                                let file_container;
                            updatedUploadForms[name] = { };
                            for (const key in files) {
             if (files.hasOwnProperty(key)) {
                 const file = files[key];
                            console.log(file);
                            for(const key_file in file){
                     if (file.hasOwnProperty(key_file)) {
                         const value = file[key_file];

                            if(typeof value === "object" && value !== null){
                                file_container = value;
                         }
                     }
                 }
                            updatedUploadForms[name][file.id] = { }
                            if(file_container){
                                updatedUploadForms[name][file.id].file = file_container;
                            updatedUploadForms[name][file.id].visible = false;
                 } else {
                                updatedUploadForms[name][file.id].file = "";
                            updatedUploadForms[name][file.id].visible = true;
                 }
                            file_container = undefined;
             }
         }
                            setUploadForms(updatedUploadForms);
     }*/
    const handleRemoveAttachment = (dataContentEntry, file) => {
        Modal.confirm({
            title: 'Datei löschen',
            content: 'Möchten Sie die Datei wirklich löschen?',
            onOk() {
                handleRemoveAttachmentConfirmed(dataContentEntry, file);
            },
            onCancel() {
                console.log('Löschung abgebrochen');
            },
        });
    };

    const handleRemoveAttachmentConfirmed = async (dataContentEntry, file) => {
        try {
            const response = await fetch(`${getAPIurl()}/helper/acl/upload/${file}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });

            if (response.ok) {
                const responseData = await response.json();


            } else {
                console.log(`Request failed with status ${response.status}`);
            }
        } catch (error) {
            return error;
        }
        if(dataContentEntry != "") {
            updateDataContentContainer = dataContent;
            updateDataContent(dataContentEntry, "-", updateDataContentContainer, "updateByID");
            putBody.data = dataContent;
            updateGutachten(putBody, true, false);
        }
    }

    const FileChanged = (name, id, component) => {
        currentFileChanged = name;
        currentFileChangedID = id;
        currentFileChangedComponent = component;
    };
    useEffect(() => formFormular1.resetFields(), [initialValues]);
    return (
        <div className={`${isSwitchOn ? 'bg-black' : 'bg-white'} sm:p-[20px] p-[10px] rounded-[30px]`}>
            {loading && <LoadingScreen />}
            {saveIcon1 && <SaveIcon saveIcon={saveIcon} />}
            {renderModals()}
            <h2 className={`${isSwitchOn ? 'text-white' : 'text-black'} font-bold text-16px  md:text-[28px] mb-[20px] generalText`}>Allgemeine Angaben</h2>
            <div>
                {dataContent && initialValues && enumerationValuesContent && ( // Bedingung überprüft, ob dataContent bereits vorhanden ist
                    <Form
                        form={formFormular1}
                        onBlur={(e) => {
                            // Hier wird das onBlur-Ereignis für das gesamte Formular abgefangen
                            //console.log(e.target);
                            if (e.target.type !== "button" || e.target.role == "switch") { //Not at addCard
                                updateDataContentContainer = dataContent;
                                // if enumarations
                                if (e.target.type === "search") {
                                    updateDataContentValue = selectedOption;
                                    setSelectedOption(null); //Reset (for the case the next select is choosen without value)
                                } else if (e.target.role == "switch") {
                                    updateDataContentValue = e.target.getAttribute('aria-checked') === 'true';
                                } else {
                                    updateDataContentValue = e.target.value;
                                }
                                if (e.target.placeholder === "Datum auswählen" && e.target.value == "") { //In case empty date
                                    updateDataContentValue = null;
                                }
                                // if components
                                const updateByIDArray = ["AllgAng__Ortstermin", "AllgAng__Unterlagen"];
                                if (updateByIDArray.some(value => e.target.id.includes(value))) {
                                    updateDataContent(e.target.id, updateDataContentValue, updateDataContentContainer, "updateByID");
                                } else {
                                    updateDataContent(e.target.id, updateDataContentValue, updateDataContentContainer, "updateByIndex");
                                }
                                updateDataContentValue = "";
                                putBody.data = dataContent;
                                if (e.target.id.includes("undefined")) {
                                    updateGutachten(putBody, true, false);//ForceReload
                                    const elements = document.getElementsByClassName("button_new_component");
                                    for (let i = 0; i < elements.length; i++) {
                                        elements[i].hidden = false;
                                    }
                                } else {
                                    updateGutachten(putBody, false);
                                }
                            }
                        }}
                        initialValues={initialValues}
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        layout="horizontal"
                        // style={{ maxWidth: 800 }}
                    >
                        <div id="organisatorisches" className="scrollable-section">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Organisatorisches</h3>
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''} `}>
                                <Form.Item label="Gutachtenart" name='AllgAng__AllgAng_Detail__GA_ArtV'>
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.sub_com_GA_Art?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Gutachtenart"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                                <Form.Item label="Kurzgutachten" name="AllgAng__AllgAng_Detail__GA_KGA">
                                    <span
                                        className={`mr-[10px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Nein</span>
                                    <Switch id="AllgAng__AllgAng_Detail__GA_KGA" className=""
                                            defaultChecked={initialValues.AllgAng__AllgAng_Detail__GA_KGA}/><span
                                    className={` ml-[10px] ${isSwitchOn ? 'text-white' : 'text-black'}`}>Ja</span>
                                </Form.Item>

                                <Form.Item label="Objektart" name='AllgAng__AllgAng_Detail__ObjektartV'>
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.sub_com_Objektart?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Objektart"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                                <Form.Item label="Straße" name="AllgAng__AllgAng_Detail__Strasse">
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Nr / Zusatz" name="AllgAng__AllgAng_Detail__NrZusatz">
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="PLZ" name="AllgAng__AllgAng_Detail__PLZ">
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Ort" name="AllgAng__AllgAng_Detail__Ort">
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Ortsteil" name="AllgAng__AllgAng_Detail__Ortsteil">
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label={`Wertermittlungsstichtag`}>
                                    <Form.Item name={`AllgAng__AllgAng_Detail__GA_Wertermittlungsstichtag`} noStyle>
                                        <DatePicker format={"DD.MM.YYYY"} className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label={`Qualitätsstichtag`}>
                                    <Form.Item name={`AllgAng__AllgAng_Detail__GA_Qualistichtag`} noStyle>
                                        <DatePicker format={"DD.MM.YYYY"} className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                                <Form.Item label={`Tag der Ortsbesichtigung`}>
                                    <Form.Item name={`AllgAng__AllgAng_Detail__GA_TagOB`} noStyle>
                                        <DatePicker format={"DD.MM.YYYY"} className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                            </div>

                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label={`Ortstermin Zusammenfassung:`}
                                           name={`AllgAng__AllgAng_Detail__OT_Zusammenfassung`}>
                                    <TextArea autoSize={true} className="textInput"/>
                                </Form.Item>
                            </div>
                            <div className={`allgemeineClass mt-[20px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Ausfertigungen" name="AllgAng__AllgAng_Detail__GA_Ausfertigungen">
                                    <MyInputNumber className="selectInput"/>
                                </Form.Item>
                                <Form.Item label="Zweck" name="AllgAng__AllgAng_Detail__GA_ZweckV">
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.sub_com_GA_Zweck?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Zweck"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                            </div>

                            <div className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Allgemeine Beschreibung"
                                           name="AllgAng__AllgAng_Detail__GA_Beschreibung">
                                    <TextArea autoSize={true} className="textInput"
                                              ref={(element) => handleRef('AllgAng__AllgAng_Detail__GA_Beschreibung', element)}/>
                                </Form.Item>
                                <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                        onClick={() => handleOpenTextbausteine('AllgAng__AllgAng_Detail__GA_Beschreibung', 'Allgemeine Beschreibung', 'Auftragsbeschreibung')}><FileTextOutlined/></Button>

                            </div>
                            <div className={`allgemeineClass mt-[20px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Vereinbartes Fertigstellungsdatum">
                                    <Form.Item name={`AllgAng__AllgAng_Detail__GA_Datum_Vereinbart`} noStyle>
                                        <DatePicker format={"DD.MM.YYYY"} className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                            <div className={`allgemeineClass mt-[20px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Form.Item label="Tatsächliches Fertigstellungsdatum">
                                    <Form.Item name={`AllgAng__AllgAng_Detail__GA_Datum`} noStyle>
                                        <DatePicker format={"DD.MM.YYYY"} className="inputsAll"/>
                                    </Form.Item>
                                </Form.Item>
                            </div>
                        </div>
                        <div id="grundbuch-kataster" className="scrollable-section">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Grundbuch
                                & Kataster</h3>
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''} `}>
                                <Form.Item label="Amtsgericht" name='AllgAng__GB_Amtsgericht'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Bezirk" name='AllgAng__GB_Bezirk'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Gemarkung" name='AllgAng__GB_Gemarkung'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Blattnummer" name='AllgAng__GB_Blatt'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Laufende Nummer" name='AllgAng__GB_laufNr'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Gemarkung" name='AllgAng__KA_Gemarkung'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Flur" name='AllgAng__KA_Flur'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Flurstücke" name='AllgAng__KA_Flurstueck'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                            </div>
                        </div>
                        <div id="sondereigentum" className="scrollable-section">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Sondereigentum</h3>
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''} `}>
                                <Form.Item label="Mieteigentumsanteil" name='AllgAng__SE_MEA'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Mieteigentumsanteil gesamt" name='AllgAng__SE_MEA_ges'>
                                    <MyInputNumber className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Objektart" name='AllgAng__SE_ObjektartV'>
                                    <AutoComplete
                                        className={"AutoCompleteTextArea"}
                                        options={enumerationValuesContent.com_SE_Objektart?.map(
                                            (option, index) => ({value: option, key: index})
                                        )}
                                        filterOption={(inputValue, option) =>
                                            option.value
                                                .toUpperCase()
                                                .indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        onChange={handleSelectChange} // Add your change handler if needed
                                    >
                                        <TextArea
                                            placeholder="Objektart"
                                            autoSize={true}
                                            className="textInput"
                                        />
                                    </AutoComplete>

                                </Form.Item>
                                <Form.Item label="Anschriften" name='AllgAng__SE_Anschriften'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Lage" name='AllgAng__SE_Lage'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Bezeichnung" name='AllgAng__SE_BEzeichnung'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Nebenräume" name='AllgAng__SE_Nebenraeume'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                                <Form.Item label="Sondernutzungsrecht" name='AllgAng__SE_SNR'>
                                    <Input className="inputsAll"/>
                                </Form.Item>
                            </div>
                        </div>

                        <div id="ortstermin" className="scrollable-section border-t border-gray-300">
                            <div
                                className="flex justify-between items-center p-[10px]  md:p-[20px] pr-[3%] md:pr-[10%]">
                                <h3 className={`font-bold text-16px  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Teilnehmer
                                    Ortstermin</h3>

                            </div>
                            {cards.Ortstermin?.map((entry, index) => (
                                <Card className={`${isSwitchOn ? 'bgdark' : 'bgwhite'}`} size="small" key={index}>
                                    <div
                                        className={`flex justify-center  sacherCenterText ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Name Teilnehmer:`}
                                                   name={`AllgAng__Ortstermin__${entry.id}__NameTeilnehmer`}>
                                            <Input className="inputsAll"/>
                                        </Form.Item>
                                        <div className="ml-[10px] gap-[10px] flex">
                                            <div style={{cursor: 'pointer'}}
                                                 onClick={() => deleteCard("Ortstermin", index, entry.id)}>
                                                <RiDeleteBin5Fill
                                                    className="text-[#FF2E3B] w-[18px] h-[20px] mt-[40px] sm:mt-0"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Kategorie Teilnehmer:`}
                                                   name={`AllgAng__Ortstermin__${entry.id}__TeilnehmerArtV`}>
                                            <AutoComplete
                                                className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                options={enumerationValuesContent.sub_com_TeilnehmerArt?.map(
                                                    (option, index) => ({value: option, key: index})
                                                )}
                                                filterOption={(inputValue, option) =>
                                                    option.value
                                                        .toUpperCase()
                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={handleSelectChange} // Add your change handler if needed
                                            >
                                                <TextArea
                                                    placeholder="Kategorie Teilnehmer"
                                                    autoSize={true}
                                                    className="textInput"
                                                />
                                            </AutoComplete>

                                        </Form.Item>
                                    </div>
                                </Card>
                            ))}
                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={` ${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[10px] md:text-[14px] m-[20px] button_new_component rounded-[30px] border-none`}
                                    onClick={() => addCard("Ortstermin")} id={"button_new_component_Ortstermin"}>Ortstermin
                                    Teilnehmer hinzufügen</Button>
                            </div>
                        </div>
                        <div id="unterlagen" className="scrollable-section  border-gray-300">
                            <div className="flex justify-between items-center p-[20px] pr-[10%]">
                                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Unterlagen</h3>

                            </div>

                            {cards.Unterlagen?.map((entry, index) => (
                                <Card className={` ${isSwitchOn ? 'bgdark' : 'bgwhite'}`} size="small" key={index}>
                                    <div className="flex md:justify-center gap-[20px] justify-start">
                                        <div
                                            className={`${isSwitchOn ? 'maxColor' : ''} flex justify-start md:justify-center sacherCenterText1`}>
                                            <Form.Item label={`Auswahl`}
                                                       name={`AllgAng__Unterlagen__${entry.id}__UN_AuswahlV`}>
                                                <AutoComplete
                                                    className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                    options={enumerationValuesContent.sub_com_UN_Auswahl?.map(
                                                        (option, index) => ({value: option, key: index})
                                                    )}
                                                    filterOption={(inputValue, option) =>
                                                        option.value
                                                            .toUpperCase()
                                                            .indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                    onChange={handleSelectChange} // Add your change handler if needed
                                                >
                                                    <TextArea
                                                        placeholder="Auswahl"
                                                        autoSize={true}
                                                        className="textInput"
                                                    />
                                                </AutoComplete>

                                            </Form.Item>
                                            <div style={{marginLeft: "20px", cursor: 'pointer'}}
                                                 onClick={() => deleteCard("Unterlagen", index, entry.id)}>
                                                <RiDeleteBin5Fill
                                                    className="text-[#FF2E3B] w-[18px] h-[20px] mt-40px sm:mt-0"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`flex-col  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                        <Form.Item label={`Anforderer/Übermittler`}
                                                   name={`AllgAng__Unterlagen__${entry.id}__UN_StakeholderV`}>
                                            <AutoComplete
                                                className={"AutoCompleteTextArea AutoCompleteTextAreaSmall"}
                                                options={enumerationValuesContent.sub_com_UN_Stakeholder?.map(
                                                    (option, index) => ({value: option, key: index})
                                                )}
                                                filterOption={(inputValue, option) =>
                                                    option.value
                                                        .toUpperCase()
                                                        .indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                onChange={handleSelectChange} // Add your change handler if needed
                                            >
                                                <TextArea
                                                    placeholder="Anforderer/Übermittler"
                                                    autoSize={true}
                                                    className="textInput"
                                                />
                                            </AutoComplete>

                                        </Form.Item>
                                        <Form.Item label="Quelle" name={`AllgAng__Unterlagen__${entry.id}__UN_Quelle`}>
                                            <Input className="inputsAll"/>
                                        </Form.Item>

                                        <Form.Item label={`Datum Versand`}>
                                            <Form.Item name={`AllgAng__Unterlagen__${entry.id}__UN_Date_Versand`}
                                                       noStyle>
                                                <DatePicker format={"DD.MM.YYYY"} className="inputsAll"/>
                                            </Form.Item>
                                        </Form.Item>
                                        <Form.Item label={`Datum Erhalt`}>
                                            <Form.Item name={`AllgAng__Unterlagen__${entry.id}__UN_Date_Erhalt`}
                                                       noStyle>
                                                <DatePicker format={"DD.MM.YYYY"} className="inputsAll"/>
                                            </Form.Item>
                                        </Form.Item>
                                    </div>

                                    <Form.Item label="Dokument" name={`AllgAng__Unterlagen__${entry.id}__UN_Doc`}
                                               className={`${isSwitchOn ? 'maxColor' : ''} max-content3`}>
                                        {uploadForms["Unterlagen"][entry.id] && uploadForms["Unterlagen"][entry.id].visible ? (
                                            <>
                                                {dataContent.AllgAng.Unterlagen[index] && dataContent.AllgAng.Unterlagen[index].UN_AuswahlV && (
                                                    <Button
                                                        icon={<DownloadOutlined/>}
                                                        onClick={() => downloadRenderFile("Anforderung", "docx", auftragID, dataContent.AllgAng.Unterlagen[index].UN_AuswahlV)}
                                                        style={{cursor: 'pointer'}}
                                                    >
                                                        Vorlage Anforderung herunterladen
                                                    </Button>
                                                )}
                                                <Upload.Dragger customRequest={customUploadFile}
                                                                onChange={(info) => FileChanged(`AllgAng__Unterlagen__${entry.id}__UN_Doc`, entry.id, "Unterlagen")}
                                                                accept="image/jpg,image/jpeg,image/png,.doc,.docx,.xls,.xlsx,.pdf">
                                                    <p className="ant-upload-drag-icon">
                                                        <UploadOutlined/>
                                                    </p>
                                                    <p className="ant-upload-text">Klicke oder ziehe Dateien hierhin, um
                                                        sie hochzuladen</p>
                                                    <p className="ant-upload-hint">Unterstützte Dateiformate: .jpg,
                                                        .png, .pdf, .doc(x), .xls(x)</p>
                                                </Upload.Dragger>
                                            </>
                                        ) : uploadForms["Unterlagen"][entry.id] !== undefined ? (
                                            <>
                                                <div>
                                                    <Button icon={<ExportOutlined/>}
                                                            onClick={() => openS3File(uploadForms["Unterlagen"][entry.id].file.url)}>{uploadForms["Unterlagen"][entry.id].file.name}</Button>
                                                    <Button icon={<DeleteOutlined/>}
                                                            onClick={() => handleRemoveAttachment(`AllgAng__Unterlagen__${entry.id}__UN_Doc`, uploadForms["Unterlagen"][entry.id].file.id)}>
                                                        Löschen
                                                    </Button>
                                                </div>
                                            </>
                                        ) : null}
                                    </Form.Item>
                                </Card>
                            ))}
                            <div className={`flex  justify-center sacherCenter1 ${isSwitchOn ? 'maxColor' : ''}`}>
                                <Button
                                    className={` ${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[10px] md:text-[14px] m-[20px] button_new_component rounded-[30px] border-none`}
                                    onClick={() => addCard("Unterlagen", true)} id={"button_new_component_Unterlagen"}>Unterlagen
                                    hinzufügen</Button>
                            </div>
                        </div>
                        <div id="hinweise" className="scrollable-section">
                            <h3 className={`font-bold text-16px mb-[12px]  md:text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Hinweise</h3>
                            <div className={`allgemeineClass ${isSwitchOn ? 'maxColor' : ''} `}>
                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label={`Urheber/Haftung 1:`}
                                               name={`AllgAng__HinweiseUrheberHaftung1`}>
                                        <TextArea autoSize={true} className="textInput"
                                                  ref={(element) => handleRef('AllgAng__HinweiseUrheberHaftung1', element)}/>
                                    </Form.Item>

                                    <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                            onClick={() => handleOpenTextbausteine('AllgAng__HinweiseUrheberHaftung1', 'Hinweise Urheber/Haftung 1', 'Hinweise Urheber/Haftung 1')}><FileTextOutlined/></Button>
                                </div>
                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label={`Urheber/Haftung 2:`}
                                               name={`AllgAng__HinweiseUrheberHaftung2`}>
                                        <TextArea autoSize={true} className="textInput"
                                                  ref={(element) => handleRef('AllgAng__HinweiseUrheberHaftung2', element)}/>
                                    </Form.Item>
                                    <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                            onClick={() => handleOpenTextbausteine('AllgAng__HinweiseUrheberHaftung2', 'Hinweise Urheber/Haftung 2', 'Hinweise Urheber/Haftung 2')}><FileTextOutlined/></Button>
                                </div>
                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label={`Rechtsgrundlagen:`}
                                               name={`AllgAng__HinweiseRechtsgrundlagen`}>
                                        <TextArea autoSize={true} className="textInput"
                                                  ref={(element) => handleRef('AllgAng__HinweiseRechtsgrundlagen', element)}/>
                                    </Form.Item>


                                    <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                            onClick={() => handleOpenTextbausteine('AllgAng__HinweiseRechtsgrundlagen', 'Hinweise Rechtsgrundlagen', 'Hinweise Rechtsgrundlagen')}><FileTextOutlined/></Button>
                                </div>
                                <div
                                    className={`flex textOuter justify-start gap-[10px] ${isSwitchOn ? 'maxColor' : ''}`}>
                                    <Form.Item label={`Literatur`}
                                               name={`AllgAng__HinweiseLiteratur`}>
                                        <TextArea autoSize={true} className="textInput"
                                                  ref={(element) => handleRef('AllgAng__HinweiseLiteratur', element)}/>
                                    </Form.Item>


                                    <Button className="mt-[35px] md:mt-0 md:ml-[13px] ml-[20px] text-[#FF2E3B] fileIcon"
                                            onClick={() => handleOpenTextbausteine('AllgAng__HinweiseLiteratur', 'Hinweise Literatur', 'Hinweise Literatur')}><FileTextOutlined/></Button>
                                </div>
                            </div>
                        </div>

                    </Form>
                )}
            </div>
        </div>
    );
};

export default AllgemeineAngaben;
