import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useSwitch} from "../../pages/SwitchContext";
import {
    getAPIurl,
    LoadingScreen,
    openS3File,
} from "../../helpers/formHelpers";
import {getToken} from "../../helpers";
import {Button, message, Space, Table, Tooltip} from "antd";
import {CopyOutlined, ExportOutlined} from "@ant-design/icons";

let dataContainerGutachten;
let dataContainerAuftrag;


const DokumenteLinks = () => {
    const [loading, setLoading] = useState(false);
    const { auftragID, gutachtenID } = useParams();
    const { isSwitchOn, toggleSwitch } = useSwitch();
    const [tableData, setTableData] = useState(null);
    const [tableDataLink, setTableDataLink] = useState(null);

    const fetchDokumenteLinks = async () => {
        setLoading(true);
        let tableDataValues = [];
        let tableDataLinkValues = [];

        try {

            const responseAuftrag = await fetch(`${getAPIurl()}/helper/acl/auftrag/${auftragID}?populate[0]=UN_Auftrag&populate[1]=Links`, {
                // &filters[role][$eq]=${userRole}
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            dataContainerAuftrag = await responseAuftrag.json();

            const responseGutachten = await fetch(`${getAPIurl()}/helper/acl/gutachten/${gutachtenID}?populate=deep`, {
                // &filters[role][$eq]=${userRole}
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            });
            dataContainerGutachten = await responseGutachten.json();

            // Get document data from UN_Auftrag
            const UN_Auftrag = dataContainerAuftrag.UN_Auftrag;
            for (const key in UN_Auftrag) {
                if (UN_Auftrag.hasOwnProperty(key)) {
                    if (typeof UN_Auftrag[key] === "object" && UN_Auftrag[key] !== null && UN_Auftrag[key].id) {
                        UN_Auftrag[key].kategorie = "Auftrag";
                        if(key=="Vollmacht_Doc") {
                            UN_Auftrag[key].beschreibung = "Vollmacht";
                        } else if(key=="Auftrag_Doc") {
                            UN_Auftrag[key].beschreibung = "Auftrag";
                        }
                        tableDataValues.push(UN_Auftrag[key]);
                    }
                }
            }

            // Get document data from AllgAng.Unterlagen
            for (const currentComponentKey in dataContainerGutachten.AllgAng.Unterlagen) {
                const currentComponent = dataContainerGutachten.AllgAng.Unterlagen[currentComponentKey];
                for (const key in currentComponent) {
                    if (currentComponent.hasOwnProperty(key)) {
                        if (typeof currentComponent[key] === "object" && currentComponent[key] !== null && currentComponent[key].id) {
                            currentComponent[key].kategorie = "Unterlagen";
                            currentComponent[key].beschreibung = currentComponent.UN_AuswahlV;
                            tableDataValues.push(currentComponent[key]);
                        }
                    }
                }
            }
            // Get document data from Anlagen
            /*for (const currentComponentKey in dataContainerGutachten.data.attributes.Anlagen) {
                const currentComponent = dataContainerGutachten.data.attributes.Anlagen[currentComponentKey];
                for (const key in currentComponent) {
                    if (currentComponent.hasOwnProperty(key)) {
                        for (const nestedKey in currentComponent[key]) {
                            if (typeof currentComponent[key][nestedKey] === "object" && currentComponent[key][nestedKey] !== null && currentComponent[key][nestedKey].id) {
                                currentComponent[key][nestedKey].kategorie = nestedKey;
                                tableDataValues.push(currentComponent[key][nestedKey]);
                            }
                        }
                    }
                }
            }*/

            //Docs
            setTableData(tableDataValues.map((item, index) => {
                return {
                    table: "Dokumente",
                    key: index,
                    id: item.id,
                    url: item.url,
                    kategorie: item.kategorie,
                    beschreibung: item.beschreibung,
                };
            }))

            //Links
            for (const LinkKey in dataContainerAuftrag.Links) {
                tableDataLinkValues.push(dataContainerAuftrag.Links[LinkKey]);
            }
            setTableDataLink(tableDataLinkValues.map((item, index) => {
                return {
                    table: "Links",
                    key: index,
                    id: item.id,
                    url: item.URL,
                    kategorie: item.LinkkategorieV,
                    beschreibung: item.Beschreibung,
                };
            }))
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleCopy = (data) => {
        navigator.clipboard.writeText(data).then(result => {
            message.success("Inhalt wurde in die Zwischenablage kopiert.");
        });
    };

    const ensureProtocol = (url) => {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return `http://${url}`;
        }
        return url;
    };


    const columns = [
        {
            title: "Kategorie",
            dataIndex: "kategorie",
            key: "kategorie",
        },
        {
            title: "Beschreibung",
            dataIndex: "beschreibung",
            key: "beschreibung",
        },
        {
            title: "Aktionen",
            key: "actions",
            render: (text, record) => {
                return (
                    <Space size="middle">
                        {record.kategorie === 'Lokal' ? (
                            // Render Lokal specific content
                            <Tooltip title="Aus Sicherheitsgründen können lokale Ordner/Dateien nicht aus dem Browser geöffnet werden. Bitte klicken Sie hier um den Pfad zu kopieren, welchen Sie dann in Ihrem Explorer einfügen können.">
                                <Button icon={<CopyOutlined />} onClick={() => handleCopy(record.url)}>Pfad kopieren</Button>
                            </Tooltip>
                        ) : (
                            // Render content for other categories
                            record.table === "Links" ? (
                                <Link target="_blank" to={ensureProtocol(record.url)}>
                                    <Button icon={<ExportOutlined />}>
                                        Öffnen
                                    </Button>
                                </Link>
                            ) : (
                                <Button onClick={() => openS3File(record.url)}  icon={<ExportOutlined />}>
                                        Öffnen
                                </Button>
                            )
                        )}
                    </Space>
                );
            }

        },
    ];

    useEffect(() => {
        fetchDokumenteLinks().then();
    }, []);

    return (
    <div className={`${isSwitchOn ? 'bg-black' : 'bg-white'} mr-[15px] rounded-r-[30px] pt-[15px]`}>
        {loading && <LoadingScreen/>}
        <div className="grid grid-cols-8 gap-4">
            <div className="col-span-4 p-4">
                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Hochgeladene Dokumente im Gutachten</h3>
                <Link to={`/auftrag/${auftragID}/#un-auftrag`}><Button
                    className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[12px] md:text-[14px] rounded-[30px] m-[20px] button_new_component border-none `}>
                    Dokumente Auftrag bearbeiten</Button></Link>
                <Button onClick={() => {window.location.href=`/gutachten/${auftragID}/${gutachtenID}/allgemeine-angaben#unterlagen`}}
                    className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[12px] md:text-[14px] rounded-[30px] m-[20px] button_new_component border-none `}>
                    Dokumente Gutachten bearbeiten</Button>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    responsive
                    pagination={{
                        pageSizeOptions: [20, 50, 100, 150],
                        defaultPageSize: 20,
                        locale: {items_per_page: "Einträge / Seite"}
                    }}
                    style={{
                        background: "white",
                        overflowX: "auto",
                        borderRadius: "30px",
                        padding: "20px",
                    }}
                    className={`${isSwitchOn ? "bgdark" : "bgwhite"}  ${isSwitchOn ? "tableComtainer" : "tableComtainerBlack"
                    }`}
                /></div>
            <div className="col-span-4 p-4">
                <h3 className={`font-bold text-[20px] generalText ${isSwitchOn ? 'text-white' : 'text-black'}`}>Linksammlung</h3>
                <Button onClick={() => {
                    window.location.href = `/auftrag/${auftragID}#links`
                }}
                        className={`${isSwitchOn ? 'auftragBtn1' : 'auftragBtn'}  text-[12px] md:text-[14px] rounded-[30px] m-[20px] button_new_component border-none `}>
                    Linksammlung bearbeiten</Button>
                <Table
                    columns={columns}
                    dataSource={tableDataLink}
                    responsive
                    pagination={{
                        pageSizeOptions: [20, 50, 100, 150],
                        defaultPageSize: 20,
                        locale: {items_per_page: "Einträge / Seite"}
                    }}
                    style={{
                        background: "white",
                        overflowX: "auto",
                        borderRadius: "30px",
                        padding: "20px",
                    }}
                    className={`${isSwitchOn ? "bgdark" : "bgwhite"}  ${isSwitchOn ? "tableComtainer" : "tableComtainerBlack"
                    }`}
                />
            </div>
        </div>
    </div>
    );
};

export default DokumenteLinks;